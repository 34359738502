import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.SYSTEM_SETTING_FINANCIAL_SETTING_REVENUE_ITEMS,
        components: import('../views/revenue-items'),
        meta: {
            title: '付费科目'
        }
    },
    {
        path: Path.SYSTEM_SETTING_FINANCIAL_SETTING_RATE,
        components: import('../views/rate'),
        meta: {
            title: '税率设置'
        }
    },
    {
        path: Path.SYSTEM_SETTING_FINANCIAL_SETTING_STORES_PRICING,
        components: import('../views/stores-pricing'),
        meta: {
            title: '门店定价'
        }
    },
    {
        path: Path.SYSTEM_SETTING_FINANCIAL_SETTING_DISCOUNT,
        components: import('../views/discount'),
        meta: {
            title: '门店折扣'
        }
    },
    {
        path: Path.SYSTEM_SETTING_FINANCIAL_SETTING_APPROVE,
        components: import('../views/approve'),
        meta: {
            title: '门店审批'
        }
    }
];

export default config;
