import { API } from '../types';

export default function Config(): API.ExchangeDetail.Config {
    return {
        id: 0,
        tenId: 0,
        braId: 0,
        hotWaterState: 0,
        coldWaterState: 0,
        coldWaterInterface: '',
        electricState: 0,
        electricInterface: '',
        lockState: 0,
        lockInterface: '',
        payType: '',
        signType: '',
        maxUserCount: 0,
        handoverState: 0,
        lockAutoFrozen: 0,
        lockFrozenDay: 0,
        lateFeeState: 0,
        lateFeeDay: 0,
        lateFeeScale: 0,
        rentSmsState: 0,
        rentSmsDay: 0,
        rechargeSmsState: 0,
        rechargeSmsAmount: 0,
        contractSignTime: 0,
        debugState: 0
    };
}
