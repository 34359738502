/*
 * @Author: mkRui
 * @Date: 2021-09-26 10:16:38
 * @LastEditTime: 2021-11-20 12:37:56
 */
// 路由配置页面
import { FC, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from 'ye-home/src/components/loading';
import { ConfigProps } from './config';
import Path from './path';

interface RouterProps {
    baseRouter: Path;
    redirect?: Path;
    config: ConfigProps[];
}

const RouterComponents: FC<RouterProps> = (props) => {
    const { config, baseRouter, redirect } = props;
    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                {redirect && (
                    <Route
                        exact
                        path={baseRouter}
                        render={() => <Redirect to={redirect} />}
                    />
                )}
                {config.map((item) => (
                    <Route
                        key={item.path}
                        path={item.path}
                        component={lazy(() => item.components)}
                    />
                ))}
                <Route render={() => <Redirect to="/404" />} />
            </Switch>
        </Suspense>
    );
};

export default RouterComponents;
