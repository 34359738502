/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-26 18:05:54
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.SYSTEM_SETTING_BASE_SETTING_PARAMETER,
        components: import('../views/parameter'),
        meta: {
            title: '数据字典'
        }
    },
    {
        path: Path.SYSTEM_SETTING_BASE_SETTING_PAYMENT,
        components: import('../views/payment'),
        meta: {
            title: '门店支付'
        }
    },
    {
        path: Path.SYSTEM_SETTING_BASE_SETTING_DATA_DICTIONARY,
        components: import('../views/data-dictionary'),
        meta: {
            title: '数据字典项'
        }
    },
    {
        path: Path.SYSTEM_SETTING_BASE_SETTING_BASE_INFO,
        components: import('../views/base-info'),
        meta: {
            title: '基础设置'
        }
    },
    {
        path: Path.SYSTEM_SETTING_BASE_SETTING_BRANCH_LIST,
        components: import('../views/branch-list'),
        meta: {
            title: '门店列表'
        }
    },
    {
        path: Path.SYSTEM_SETTING_BASE_SETTING_BRANCH_DETAIL,
        components: import('../views/branch-detail'),
        meta: {
            title: '门店详情',
            routerPosition: Path.SYSTEM_SETTING_BASE_SETTING_BRANCH_LIST
        }
    }
];

export default config;
