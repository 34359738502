/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-15 17:15:13
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.SYSTEM_SETTING_INTERFACE_SETTINGS_INTELLIGENT_HARDWARE,
        components: import('../views/device'),
        meta: {
            title: '智能设备'
        }
    },
    {
        path: Path.SYSTEM_SETTING_INTERFACE_SETTINGS_INTERFACE_LIST,
        components: import('../views/sms'),
        meta: {
            title: '短信接口'
        }
    },
    {
        path: Path.SYSTEM_SETTING_INTERFACE_SETTINGS_ALIPAY,
        components: import('../views/alipay'),
        meta: {
            title: '支付宝接口'
        }
    },
    {
        path: Path.SYSTEM_SETTING_INTERFACE_SETTINGS_WECHAT,
        components: import('../views/wepay'),
        meta: {
            title: '微信接口'
        }
    },
    {
        path: Path.SYSTEM_SETTING_INTERFACE_SETTINGS_ACCESS_CONTROL,
        components: import('../views/access-control'),
        meta: {
            title: '门禁管理'
        }
    },
    {
        path: Path.SYSTEM_SETTING_INTERFACE_SETTINGS_ADAPAY,
        components: import('../views/adapay'),
        meta: {
            title: '汇付支付接口'
        }
    },
    {
        path: Path.SYSTEM_SETTING_INTERFACE_SETTINGS_ADAPAY_OPTION,
        components: import('../views/adapay-option'),
        meta: {
            title: '汇付支付',
            routerPosition: Path.SYSTEM_SETTING_INTERFACE_SETTINGS_ADAPAY
        }
    }
];

export default config;
