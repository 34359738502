import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.FINANCIAL_MANAGEMENT_PROCEED,
        components: import('../views/proceed'),
        meta: {
            title: '门店收款'
        }
    },
    {
        path: Path.FINANCIAL_MANAGEMENT_ELECTRONIC_RECEIPT,
        components: import('../views/electronic-receipt'),
        meta: {
            title: '电子收据'
        }
    },
    {
        path: Path.FINANCIAL_MANAGEMENT_ELECTRONIC_REFUND,
        components: import('../views/refund'),
        meta: {
            title: '退款列表'
        }
    },
    {
        path: Path.FINANCIAL_MANAGEMENT_ELECTRONIC_REFUND_DETAIL,
        components: import('../views/refund-detail'),
        meta: {
            title: '退款详情'
        }
    },
    {
        path: Path.FINANCIAL_MANAGEMENT_ELECTRONIC_REFUND_PAY,
        components: import('../views/refund-pay'),
        meta: {
            title: '退款处理'
        }
    },
    {
        path: Path.FINANCIAL_MANAGEMENT_SUSPENSE_ACCOUNT,
        components: import('../views/suspense-account'),
        meta: {
            title: '挂帐处理'
        }
    },
    {
        path: Path.FINANCIAL_MANAGEMENT_COUPON_LIST,
        components: import('../views/coupon-list'),
        meta: {
            title: '优惠券列表'
        }
    },
    {
        path: Path.FINANCIAL_MANAGEMENT_COUPON_CODE_MANAGEMENT,
        components: import('../views/coupon-search-code'),
        meta: {
            title: '券码查询'
        }
    },
    {
        path: Path.FINANCIAL_MANAGEMENT_ORDER_CHECK,
        components: import('../views/order-check'),
        meta: {
            title: '流水处理'
        }
    },
    {
        path: Path.FINANCIAL_MANAGEMENT_ADAPAY_CHECK,
        components: import('../views/adapay-check'),
        meta: {
            title: '流水处理'
        }
    },
    {
        path: Path.FINANCIAL_MANAGEMENT_WITHDRAWAL_CHECK,
        components: import('../views/withdrawal-check'),
        meta: {
            title: '提现处理'
        }
    },
    {
        path: Path.FINANCIAL_MANAGEMENT_WITHDRAWAL_BRANCH,
        components: import('../views/withdrawal-branch'),
        meta: {
            title: '门店提现'
        }
    }
];

export default config;
