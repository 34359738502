/*
 * @Author: Fullsize
 * @Date: 2021-09-26 14:43:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-24 17:11:09
 * @FilePath: /ye-home/src/stores/root.ts
 */
// todo
// 抓取store
import { action, makeObservable, observable } from 'mobx';
import { Store } from 'mor-request';
import axios from 'ye-home/src/api';
import { MenuModeEnum } from 'ye-home/src/types';
import { API } from 'ye-home/src/types/api-types';
import { Search, Tree } from 'ye-home/src/utils/tree';

// 全局
import { Api } from '../api/api';
class RootStore extends Store<Api> {
    public userInfo: API.UserInfo.Data = {
        avatar: '',
        branchNames: '',
        createdTime: '',
        deptNames: '',
        id: 0,
        lastLoginTime: '',
        loginBlockedTime: '',
        loginFailureCount: 0,
        loginState: 0,
        mobile: '',
        name: '',
        password: '',
        roleNames: '',
        state: 0,
        superAdmin: 0,
        tenId: 0,
        username: ''
    };

    public collapsed = false;

    public initInfo: API.InitInfo.Data = {
        tenId: 0,
        id: 0,
        avatar: '',
        branchId: 0,
        branchName: '',
        name: '',
        username: '',
        userType: 0,
        superAdmin: false,
        loginTime: '',
        hasRoom: false,
        hasRoomSingle: false,
        hasRoomDorm: false
    };

    public menuInfo: API.MenuInfo.Data[] = [];

    public menuMode = MenuModeEnum.MODE_ASIDE;

    public menuUrl = '';

    public menuName = '';

    constructor() {
        super(new Api(axios));
        makeObservable(this, {
            userInfo: observable,
            menuInfo: observable,
            initInfo: observable,
            menuMode: observable,
            menuUrl: observable,
            menuName: observable,
            collapsed: observable,
            $setUserInfo: action,
            $setInitInfo: action,
            $setMenuInfo: action,
            $setMenuUrl: action,
            $setMenuName: action
        });
    }

    public $setMenuUrl(url: string) {
        this.menuUrl = url;
    }

    public $setMenuName(name: string) {
        this.menuName = name;
    }

    public $setCollapsed(collapsed: boolean) {
        this.collapsed = collapsed;
    }

    public get menuAsideList() {
        const menuInfo: API.MenuInfo.Data[] = JSON.parse(
            JSON.stringify(this.menuInfo)
        );
        if (this.menuMode === MenuModeEnum.MODE_COMBINATION) {
            const menuId = Search(menuInfo, this.menuUrl);
            let nowInfo = menuInfo.find(
                (item) => item.menuUrl === this.menuUrl
            );
            if (nowInfo?.parentId !== 0) {
                nowInfo = menuInfo.find(
                    (item) => item.id === nowInfo?.parentId
                );
            }
            this.$setMenuName(nowInfo?.menuName || '');
            return new Tree(menuInfo).loop(menuId[0]);
        } else {
            return new Tree(menuInfo).loop(0);
        }
    }

    public get menuHeaderList() {
        const menuInfo: API.MenuInfo.Data[] = JSON.parse(
            JSON.stringify(this.menuInfo)
        );
        if (this.menuMode === MenuModeEnum.MODE_COMBINATION) {
            return menuInfo.filter((item) => item.parentId === 0);
        } else {
            return new Tree(menuInfo).loop(0);
        }
    }

    public get menuList(): API.MenuInfo.TreeData[] {
        const menuInfo: API.MenuInfo.Data[] = JSON.parse(
            JSON.stringify(this.menuInfo)
        );
        return new Tree(menuInfo).loop(0);
    }

    public $setMenuMode = (mode: MenuModeEnum) => {
        this.menuMode = mode;
    };

    public async init() {
        const res = await this.api.init();
        if (res.code === 0) this.$setInitInfo(res.data);
    }

    public $setInitInfo(data: API.InitInfo.Data) {
        Object.assign(this.initInfo, data);
    }

    public $setUserInfo(data: API.UserInfo.Data) {
        this.userInfo = data;
    }

    public $setMenuInfo(menuInfo: API.MenuInfo.Data[]) {
        this.menuInfo = menuInfo;
    }

    public async getUserInfo() {
        const res = await this.api.userInfo();
        if (res.code === 0) this.$setUserInfo(res.data);
    }

    public logout() {
        return this.api.logOut();
    }

    public async getMenuList() {
        const res = await this.api.getMenu();
        if (res.code === 0) {
            this.$setMenuInfo(res.data);
        }
    }
}

export const store = new RootStore();

export default RootStore;
