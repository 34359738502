import projectInfo from 'ye-home/package.json';

class MorFoundationStorage {
    private name = projectInfo.name;

    public constructor() {
        (window as any)[`${this.name}_foundation`] = {};
    }

    public get foundation() {
        return (window as any)[`${this.name}_foundation`];
    }

    public getItem(name: string): any {
        const info = this.foundation[name];
        if (!info) return '';
        return info;
    }

    public setItem(name: string, data: any) {
        const info = { ...(this.foundation[name] || {}) };
        (window as any)[`${this.name}_foundation`][name] = Object.assign(
            info,
            data
        );
    }

    public deleteItem(name: string) {
        const info = { ...(this.foundation || {}) };
        delete info[name];
        (window as any)[`${this.name}_foundation`] = info;
    }

    public refresh() {
        (window as any)[`${this.name}_foundation`] = {};
    }
}

const morFoundationStorage = new MorFoundationStorage();

export default morFoundationStorage;
