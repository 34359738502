/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-28 00:40:23
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.DEVICE_WARN_SETTING,
        components: import('../views/device-warn-setting'),
        meta: {
            title: '报警设置'
        }
    },
    {
        path: Path.DEVICE_WARN_MESSAGE,
        components: import('../views/device-warn-message'),
        meta: {
            title: '报警处理'
        }
    },
    {
        path: Path.DEVICE_WARN_BLACKLIST,
        components: import('../views/device-warn-blacklist'),
        meta: {
            title: '报警过滤'
        }
    }
];

export default config;
