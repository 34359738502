import {
    AccountOpeningEnum,
    AccountStateEnum,
    AdaPayTargetTypeEnum,
    AgreementTypeEnum,
    BranchApproveCheckType,
    CategoryEnum,
    CheckInStateEnum,
    CheckOrNoEnum,
    CheckoutCategoryEnum,
    CheckoutCheckStateEnum,
    CheckoutRuleEnum,
    CheckoutTypeEnum,
    CheckStateEnum,
    ContractStateEnum,
    CouponCategoryEnum,
    CouponRuleEnum,
    DeviceTypeEnum,
    DisabledState,
    GenderEnum,
    HrefTypeEnum,
    IdNoTypeEnum,
    MemberTypeEnum,
    MeterRadEnum,
    PayStateEnum,
    PayTypeEnum,
    PurposeEnum,
    RefundStateEnum,
    RefundTypeEnum,
    RegisterTypeEnum,
    RentBillTypeEnum,
    RentClassEnum,
    RentStateEnum,
    RentTypeEnum,
    ReserveRentStateEnum,
    RetreatCheckStateEnum,
    RetreatTypeEnum,
    SignTypeEnum,
    SmsStateEnum,
    StateEnum,
    SuspenseCheckoutTypeEnum,
    SuspenseStatusEnum,
    TargetTypeEnum,
    TransferStateEnum,
    TrueOrFalseEnum,
    WithdrawalStateEnum,
    WithdrawalTargetTypeEnum,
    HandoverFlowStateEnum,
    AppointmentStateEnum,
    RenewalStateEnum,
    ExchangeContractStateEnum,
    CheckOutCheckStateEnum,
    CostEnum,
    AppTypeEnum,
    RenewalTypeEnum,
    TimeTypeEnum
} from './enum-types';

export const agreementType = {
    [AgreementTypeEnum.PAPER]: '纸质合同',
    [AgreementTypeEnum.ELECTRONICS]: '电子合同',
    [AgreementTypeEnum.NULL]: '无'
};

export const timeType = {
    [TimeTypeEnum.CREATE]: '创建时间',
    [TimeTypeEnum.REMITTANCE]: '划账时间',
    [TimeTypeEnum.TRANSFER]: '转账时间'
};

export const gender = {
    // [GenderEnum.UNKNOWN]: '未知',
    [GenderEnum.MALE]: '男',
    [GenderEnum.FEMALE]: '女'
};

export const deviceType = {
    [DeviceTypeEnum.COMMON]: '通用设备',
    [DeviceTypeEnum.LOCK]: '智能门锁',
    [DeviceTypeEnum.HOT_WATER_METER]: '智能热水表',
    [DeviceTypeEnum.COLD_WATER_METER]: '智能冷水表',
    [DeviceTypeEnum.WATT_HOUR_METER]: '智能电表'
};

export const stateType = {
    [StateEnum.EFFECTIVE]: '有效',
    [StateEnum.INVALID]: '无效'
};

export const branchState = {
    [TrueOrFalseEnum.TRUE]: '有效',
    [TrueOrFalseEnum.FALSE]: '无效'
};

export const showState = {
    [CheckOrNoEnum.TRUE]: '显示',
    [CheckOrNoEnum.FALSE]: '不显示'
};

export const disabledStateType = {
    [DisabledState.DISABLE]: '禁用',
    [DisabledState.NORMAL]: '正常'
};

export const rentBillType = {
    [RentBillTypeEnum.BY_NATURAL_MONTH]: '按自然月',
    [RentBillTypeEnum.BY_CROSS_MONTH]: '按跨月'
};

export const rentType = {
    [RentTypeEnum.WHOLE_RENT]: '整租',
    [RentTypeEnum.SCATTERED_RENT]: '散租',
    [RentTypeEnum.DORMITORY_RENT]: '宿舍'
};

export const renewalType = {
    [RenewalTypeEnum.MONTH_RENT]: '月租房'
};

export const rentStateType = {
    [RentStateEnum.NOT_RENTED]: '未租',
    [RentStateEnum.RENTED]: '已租',
    [RentStateEnum.BOOKED]: '已预订',
    [RentStateEnum.SUSPENDED]: '暂停使用'
};

export const meterRadType = {
    [MeterRadEnum.NO_SETTLEMENT_REQUIRED]: '无需结算',
    [MeterRadEnum.NO_SHARED_STATEMENT]: '单表无公摊结算',
    [MeterRadEnum.SHARED_SETTLEMENT_LEASE]: '按租约公摊结算',
    [MeterRadEnum.SHARED_SETTLEMENT_OCCUPANCY]: '按入住数公摊结算'
};

export const idNoType = {
    [IdNoTypeEnum.HONG_KONG]: '香港往来通行证',
    [IdNoTypeEnum.TAI_WAN]: '台湾往来大陆通行证',
    [IdNoTypeEnum.MACAO]: '澳门往来通行证',
    [IdNoTypeEnum.PASSPORT]: '护照',
    [IdNoTypeEnum.OTHER]: '其他',
    [IdNoTypeEnum.ID_CARD]: '大陆身份证'
};

export const reserveRentState = {
    [ReserveRentStateEnum.ALL]: '全部',
    [ReserveRentStateEnum.SCHEDULED_UNPAID]: '预订未支付',
    [ReserveRentStateEnum.BOOKED]: '已预订',
    [ReserveRentStateEnum.SIGNED]: '已签约',
    [ReserveRentStateEnum.RETIRED]: '已退订',
    [ReserveRentStateEnum.CANCEL]: '已取消'
};

export const renewalState = {
    [RenewalStateEnum.ALL]: '全部',
    [RenewalStateEnum.NOT_SIGNED]: '未签约',
    [RenewalStateEnum.SIGNED]: '已签约',
    [RenewalStateEnum.CANCEL]: '已取消'
};

export const signType = {
    [SignTypeEnum.MAKE]: '预订转签约',
    [SignTypeEnum.NOW]: '直接签约'
};

export const exchangeSignType = {
    [TrueOrFalseEnum.FALSE]: '未签名',
    [TrueOrFalseEnum.TRUE]: '已签名'
};

export const payType = {
    [PayTypeEnum.ADA_PAY]: '汇付天下支付',
    [PayTypeEnum.CASH_PAYMENT]: '现金',
    [PayTypeEnum.ALIPAY_APP_PAYMENT]: 'app支付宝支付',
    [PayTypeEnum.WECHAT_APP_PAYMENT]: 'app微信支付',
    [PayTypeEnum.ALIPAY_APPLICATION_PAYMENT]: '支付宝小程序支付',
    [PayTypeEnum.WECHAT_APPLICATION_PAYMENT]: '微信小程序支付',
    [PayTypeEnum.WECHAT_PAYMENT]: '微信扫码支付',
    [PayTypeEnum.ALIPAY_PAYMENT]: '支付宝扫码支付',
    [PayTypeEnum.WECHAT_QR_CODE_PAYMENT]: '微信付款码支付',
    [PayTypeEnum.ALIPAY_QR_CODE_PAYMENT]: '支付宝付款码支付',
    [PayTypeEnum.OWNER_OFFLINE_PAYMENT]: '房东线下支付',
    [PayTypeEnum.BANK_PAY]: 'POS机银行卡支付',
    [PayTypeEnum.PUBLIC_LOANS]: '对公打款',
    [PayTypeEnum.REFUND]: '退款'
};

export const couponRuleType = {
    [CouponRuleEnum.FULL_REDUCTION_AMOUNT]: '满减金额',
    [CouponRuleEnum.REDUCED_AMOUNT]: '立减金额'
};

export const checkoutRuleType = {
    [CheckoutRuleEnum.NO_DEPOSIT_NO_RENT]: '不退押金不退租金',
    [CheckoutRuleEnum.DEPOSIT_NO_RENT]: '退租金不退押金',
    [CheckoutRuleEnum.RENT_NO_DEPOSIT]: '退押金不退租金',
    [CheckoutRuleEnum.RENT_DEPOSIT]: '退租金退押金'
};

export const bindStatus = {
    [TrueOrFalseEnum.FALSE]: '未绑定',
    [TrueOrFalseEnum.TRUE]: '已绑定'
};

export const usedState = {
    [TrueOrFalseEnum.FALSE]: '未使用',
    [TrueOrFalseEnum.TRUE]: '已使用'
};

export const isSuperAdmin = {
    [TrueOrFalseEnum.FALSE]: '否',
    [TrueOrFalseEnum.TRUE]: '是'
};

export const loginState = {
    [TrueOrFalseEnum.FALSE]: '锁定',
    [TrueOrFalseEnum.TRUE]: '正常'
};

export const checkStatus = {
    [TrueOrFalseEnum.FALSE]: '未处理',
    [TrueOrFalseEnum.TRUE]: '已处理'
};

export const leaseStatus = {
    [TrueOrFalseEnum.FALSE]: '未签约',
    [TrueOrFalseEnum.TRUE]: '已签约'
};

export const rentState = {
    [CheckOrNoEnum.FALSE]: '已欠款',
    [CheckOrNoEnum.TRUE]: '未欠款'
};

export const couponCategory = {
    [CouponCategoryEnum.RENT]: '租金',
    [CouponCategoryEnum.WATER_ELECTRICITY]: '水电费'
};

export const menuCategory = {
    [CheckOrNoEnum.TRUE]: '内链',
    [CheckOrNoEnum.FALSE]: '外链'
};

export const menuState = {
    [CheckOrNoEnum.TRUE]: '是',
    [CheckOrNoEnum.FALSE]: '否'
};

export const dormState = {
    [TrueOrFalseEnum.TRUE]: '是',
    [TrueOrFalseEnum.FALSE]: '否'
};

export const liveState = {
    [CheckOrNoEnum.TRUE]: '是',
    [CheckOrNoEnum.FALSE]: '否'
};

export const checkOrNoState = {
    [CheckOrNoEnum.TRUE]: '是',
    [CheckOrNoEnum.FALSE]: '否'
};

export const categoryDept = {
    [CheckOrNoEnum.TRUE]: '公司',
    [CheckOrNoEnum.FALSE]: '部门'
};

export const receiptState = {
    [CheckOrNoEnum.TRUE]: '已生成',
    [CheckOrNoEnum.FALSE]: '未生成'
};

export const payState = {
    [CheckOrNoEnum.TRUE]: '未支付',
    [CheckOrNoEnum.FALSE]: '已支付'
};

export const invoiceState = {
    [CheckOrNoEnum.TRUE]: '已开票',
    [CheckOrNoEnum.FALSE]: '未开票'
};

export const readState = {
    [CheckOrNoEnum.TRUE]: '未读',
    [CheckOrNoEnum.FALSE]: '已读'
};

export const lockState = {
    [CheckOrNoEnum.TRUE]: '开通',
    [CheckOrNoEnum.FALSE]: '关闭'
};

export const meterState = {
    [CheckOrNoEnum.TRUE]: '开闸',
    [CheckOrNoEnum.FALSE]: '关闸'
};

export const couponStatusOptions = {
    [CheckOrNoEnum.TRUE]: '有效',
    [CheckOrNoEnum.FALSE]: '已作废'
};

export const checkStateOptions = {
    [CheckOrNoEnum.TRUE]: '通过',
    [CheckOrNoEnum.FALSE]: '不通过'
};

export const readingType = {
    [CheckOrNoEnum.TRUE]: '自动读表',
    [CheckOrNoEnum.FALSE]: '手工抄表'
};

export const paymentType = {
    [CheckOrNoEnum.TRUE]: '未打款',
    [CheckOrNoEnum.FALSE]: '已打款'
};

export const startUsing = {
    [CheckOrNoEnum.TRUE]: '已启用',
    [CheckOrNoEnum.FALSE]: '未启用'
};

export const meterCategoryType = {
    [CheckOrNoEnum.TRUE]: '房间费用',
    [CheckOrNoEnum.FALSE]: '公摊费用'
};

export const stateCheckType = {
    [CheckOrNoEnum.TRUE]: '未使用',
    [CheckOrNoEnum.FALSE]: '已使用'
};

export const dealWithStateOptions = {
    [CheckOrNoEnum.TRUE]: '未处理',
    [CheckOrNoEnum.FALSE]: '已处理'
};

export const branchApproveCheckTypeOptions = {
    [BranchApproveCheckType.CheckUser]: '审批用户',
    [BranchApproveCheckType.CheckRole]: '审批角色'
};

export const payStateType = {
    [PayStateEnum.PAID]: '已支付',
    [PayStateEnum.UNPAID]: '未支付',
    [PayStateEnum.FAIL_PAID]: '支付失败',
    [PayStateEnum.CANCEL_PAID]: '取消支付'
};

export const purposeType = {
    // 长租房
    [PurposeEnum.LONG_TERM_RENTAL]: '长租房',
    [PurposeEnum.SELF_USE]: '自用房',
    [PurposeEnum.STAFF_DORMITORY]: '员工宿舍'
};

export const categoryType = {
    [CategoryEnum.OPERATION_PLATFORM]: '运营平台',
    [CategoryEnum.LONG_RENT_APARTMENT]: '长租公寓'
};

export const hrefType = {
    [HrefTypeEnum.PAGE]: '内部网页',
    [HrefTypeEnum.URL]: '外部网页'
};

export const checkoutCategoryType = {
    [CheckoutCategoryEnum.GENERAL_CHECK_OUT]: '普通退房',
    [CheckoutCategoryEnum.CHECK_OUT_ON_CREDIT]: '挂账退房'
};

export const checkoutType = {
    [CheckoutTypeEnum.EARLY_CHECK_OUT]: '提前退房',
    [CheckoutTypeEnum.CHECK_OUT]: '正常退房',
    [CheckoutTypeEnum.DELAY_CHECK_OUT]: '逾期退房'
};

export const memberType = {
    [MemberTypeEnum.SIGNATORY]: '签约人',
    [MemberTypeEnum.ROOMMATE]: '同住人'
};

export const targetType = {
    [TargetTypeEnum.BRANCH_PAYMENT]: '门店收款',
    [TargetTypeEnum.LEASE_BILL]: '租约账单',
    [TargetTypeEnum.RESERVE_BILL]: '预订账单',
    [TargetTypeEnum.METER_BILL]: '水电账单',
    [TargetTypeEnum.REFUND_BILL]: '退款订单',
    [TargetTypeEnum.EXCHANGE_BILL]: '换房结算单',
    [TargetTypeEnum.CHECKOUT_BILL]: '退房结算单'
};

export const checkInState = {
    [CheckInStateEnum.NOT_SIGNED]: '未签约',
    [CheckInStateEnum.SIGNED]: '已签约,未入住',
    [CheckInStateEnum.CHECKED_IN]: '已入住',
    [CheckInStateEnum.CHECKED_OUT]: '已退房',
    [CheckInStateEnum.CANCELLED]: '已取消'
};

export const accountState = {
    [AccountStateEnum.DISABLED]: '禁用',
    [AccountStateEnum.NORMAL]: '正常',
    [AccountStateEnum.NOT_ACTIVE]: '未激活'
};

export const checkState = {
    [CheckStateEnum.NO_SUBMIT]: '未提交',
    [CheckStateEnum.CHECKING]: '审批中',
    [CheckStateEnum.SUCCESS]: '审批通过',
    [CheckStateEnum.FAIL]: '审批失败'
};

export const checkoutCheckState = {
    [CheckoutCheckStateEnum.NOT_SIGNED]: '未签约',
    [CheckoutCheckStateEnum.APPLY]: '申请中',
    [CheckoutCheckStateEnum.SUCCESS]: '已完成',
    [CheckoutCheckStateEnum.REFUND]: '退款中',
    [CheckoutCheckStateEnum.CANCEL]: '已取消'
};

export const smsState = {
    [SmsStateEnum.NOT_SEND]: '未发送',
    [SmsStateEnum.SEND]: '已发送',
    [SmsStateEnum.FAIL]: '已完成'
};

export const rentClassType = {
    [RentClassEnum.MONTH_RENT]: '月租房'
};

export const retreatType = {
    [RetreatTypeEnum.DEFAULT_CANCELLATION]: '违约退订'
};

export const retreatCheckState = {
    [RetreatCheckStateEnum.APPLYING]: '申请中',
    [RetreatCheckStateEnum.SUCCESS]: '审批通过',
    [RetreatCheckStateEnum.FAIL]: '审批不通过'
};

export const refundType = {
    [RefundTypeEnum.RESERVE]: '预订退款',
    [RefundTypeEnum.CHECKOUT_OUT]: '退房退款',
    [RefundTypeEnum.EXCHANGE]: '换房退款'
};

export const contractState = {
    [ContractStateEnum.NOT_CHECKOUT]: '未退房',
    [ContractStateEnum.NOT_SIGNED_CHECKOUT]: '已退房，未签约',
    [ContractStateEnum.SIGNED]: '已完成',
    [ContractStateEnum.SIGNED_CANCEL]: '已取消'
};

export const exchangeContractState = {
    [ExchangeContractStateEnum.NOT_SIGNED]: '未签约',
    [ExchangeContractStateEnum.SIGNED_CHECK_IN]: '已签约. 未入住',
    [ExchangeContractStateEnum.CHECK_IN]: '已入住',
    [ExchangeContractStateEnum.CHECK_OUT]: '已退房',
    [ExchangeContractStateEnum.CANCEL]: '已取消'
};

export const accountOpening = {
    [AccountOpeningEnum.INITIALIZATION]: '初始化状态',
    [AccountOpeningEnum.REVIEWED]: '待审核',
    [AccountOpeningEnum.AUDIT_FAILED]: '审核失败',
    [AccountOpeningEnum.ACCOUNT_OPENING_FAILED]: '开户失败',
    [AccountOpeningEnum.ACCOUNT_OPENING_SUCCESS_NO_CREATED]:
        '开户成功但未创建结算账户',
    [AccountOpeningEnum.ACCOUNT_OPENING_SUCCESS]: '开户和创建结算账户成功'
};

export const adaPayTargetType = {
    [AdaPayTargetTypeEnum.PAY_ORDER]: '订单支付划账',
    [AdaPayTargetTypeEnum.OWNER_APPLY]: '房东申请提现',
    [AdaPayTargetTypeEnum.PERSON_APPLY]: '个人申请提现',
    [AdaPayTargetTypeEnum.BRANCH_APPLY]: '门店申请提现'
};

export const transferState = {
    [TransferStateEnum.NO_TRANSFER]: '未转账',
    [TransferStateEnum.IN_TRANSFER]: '转账中',
    [TransferStateEnum.SUCCESS_TRANSFER]: '已完成转账',
    [TransferStateEnum.FAIL_TRANSFER]: '转账失败'
};

export const withdrawalState = {
    [WithdrawalStateEnum.UNDER_REVIEW]: '审核中',
    [WithdrawalStateEnum.IN_ACCOUNT_TRANSFER]: '划账中',
    [WithdrawalStateEnum.IN_TRANSFER]: '转账中',
    [WithdrawalStateEnum.SUCCESS_WITHDRAWAL]: '提现成功',
    [WithdrawalStateEnum.FAIL_WITHDRAWAL]: '提现失败',
    [WithdrawalStateEnum.CANCEL_WITHDRAWAL]: '取消提现'
};

export const withdrawalTargetType = {
    [WithdrawalTargetTypeEnum.BRANCH_WITHDRAWAL]: '门店提现',
    [WithdrawalTargetTypeEnum.OWNER_WITHDRAWAL]: '房东提现',
    [WithdrawalTargetTypeEnum.PERSON_WITHDRAWAL]: '用户提现'
};

export const suspenseStatusOptions = {
    [SuspenseStatusEnum.APPLYING]: '申请中',
    [SuspenseStatusEnum.PAYMENTS]: '退款中/支付中',
    [SuspenseStatusEnum.FINISH]: '已完成'
};

export const checkoutTypeOptions = {
    [SuspenseCheckoutTypeEnum.BREAK_CONTRACT]: '违约提前退房',
    [SuspenseCheckoutTypeEnum.EARLY_CHECKOUT]: '提前退房'
};

export const refundState = {
    [RefundStateEnum.REFUND_BALANCE]: '退款到余额',
    [RefundStateEnum.REFUND_BANK]: '退款到银行卡'
};

export const registerType = {
    [RegisterTypeEnum.LEASE_REGISTER]: '签约注册',
    [RegisterTypeEnum.RESERVE_REGISTER]: '预定注册',
    [RegisterTypeEnum.WE_CHAT_REGISTER]: '微信小程序',
    [RegisterTypeEnum.ALI_PAY_REGISTER]: '支付宝小程序'
};

export const withdrawalType = {
    [CheckOrNoEnum.TRUE]: '提现到公司账户',
    [CheckOrNoEnum.FALSE]: '提现到私人账户'
};

export const withdrawalAutoType = {
    [CheckOrNoEnum.TRUE]: '公账',
    [CheckOrNoEnum.FALSE]: '私账'
};

export const handoverFlowState = {
    [HandoverFlowStateEnum.UNSIGNED]: '未签名',
    [HandoverFlowStateEnum.SIGNED]: '已签名',
    [HandoverFlowStateEnum.CANCELLED]: '已取消'
};

export const categoryWarn = {
    [CheckOrNoEnum.TRUE]: '设备报警',
    [CheckOrNoEnum.FALSE]: '用量报警'
};

export const levelWarn = {
    [CheckOrNoEnum.TRUE]: '一级',
    [CheckOrNoEnum.FALSE]: '二级'
};

export const stateWarn = {
    [CheckOrNoEnum.TRUE]: '未处理',
    [CheckOrNoEnum.FALSE]: '已处理'
};

export const frozenState = {
    [CheckOrNoEnum.TRUE]: '正常',
    [CheckOrNoEnum.FALSE]: '冻结'
};

export const warnState = {
    [CheckOrNoEnum.TRUE]: '是',
    [CheckOrNoEnum.FALSE]: '否'
};

export const eleState = {
    [CheckOrNoEnum.TRUE]: '是',
    [CheckOrNoEnum.FALSE]: '否'
};

export const appointmentState = {
    [AppointmentStateEnum.PROCESSED]: '已处理',
    [AppointmentStateEnum.UNTREATED]: '未处理',
    [AppointmentStateEnum.TRIP_CANCELLATION]: '行程取消'
};

export const checkOutCheckState = {
    [CheckOutCheckStateEnum.NOT_SIGNED]: '未签约',
    [CheckOutCheckStateEnum.SIGNED]: '申请中',
    [CheckOutCheckStateEnum.REFUND]: '退款中',
    [CheckOutCheckStateEnum.SUCCESS]: '已退房',
    [CheckOutCheckStateEnum.CANCELLED]: '已取消'
};

export const costType = {
    [CostEnum.ONE_TIME_FEE]: '一次性费用',
    [CostEnum.RECURRING_EXPENSES]: '周期性费用',
    [CostEnum.DEPOSIT]: '押金',
    [CostEnum.CATEGORY]: '分类'
};

export const appType = {
    [AppTypeEnum.APPLET]: '小程序',
    [AppTypeEnum.APP]: 'APP'
};
