/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-12-27 02:26:47
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.OPERATIONS_MANAGEMENT_HANDLE_EXCHANGE_HOUSES_CHECKOUT_SUBMIT,
        components: import('../views/check-out-submit'),
        meta: {
            title: '换房（提交退房）',
            routerPosition:
                Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_EXCHANGE_HOUSE
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_HANDLE_EXCHANGE_HOUSES_SUBMIT,
        components: import('../views/exchange-house-submit'),
        meta: {
            title: '确认换房'
        }
    }
];

export default config;
