/*
 * @Author: mkRui
 * @Date: 2021-10-02 00:42:33
 * @LastEditTime: 2021-11-02 22:21:13
 */
import HocUtils from 'ye-home/src/utils/hoc-utils';

import RemindStoreContext, { RemindStore } from './store';
import Remind from './view/main';

export default HocUtils<RemindStore, {}>(
    RemindStoreContext,
    RemindStore
)(Remind);
