import { useEffect } from 'react';
import eventDispatch from 'ye-home/src/utils/event-dispatch';

function useEventEffect(fn: Function, list?: any[]) {
    useEffect(() => {
        fn();
        eventDispatch.on('refresh', fn);
        return () => {
            eventDispatch.off('refresh', fn);
        };
    }, [...(list || [])]);
}

export default useEventEffect;
