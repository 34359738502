import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.SERVICE_MANAGEMENT_REPAIR,
        components: import('../views/repair'),
        meta: {
            title: '维修管理'
        }
    },
    {
        path: Path.SERVICE_MANAGEMENT_COMPLAINT,
        components: import('../views/complaint'),
        meta: {
            title: '投诉管理'
        }
    },
    {
        path: Path.SERVICE_MANAGEMENT_BOOKING,
        components: import('../views/booking'),
        meta: {
            title: '预约管理'
        }
    }
];

export default config;
