/*
 * @Author: mkRui
 * @Date: 2021-09-26 10:16:38
 * @LastEditTime: 2021-12-27 02:23:55
 */
enum Path {
    BASE = '/',
    LOGIN = '/login',
    LOGIN_CHECK = '/login-check',
    HOME = '/home',
    DEMO = '/demo',
    DASHBOARD = '/dashboard',
    BRANCH_DASHBOARD = '/branch-dashboard',

    // 个人中心
    PERSONAL_CENTER = '/personal-center',
    PERSONAL_CENTER_MESSAGE = '/personal-center/message',
    PERSONAL_CENTER_PASSWORD = '/personal-center/password',

    // 应用管理
    APPLICATION_MANAGEMENT = '/application-management',
    APPLICATION_MANAGEMENT_LIST = '/application-management/application',
    APPLICATION_MANAGEMENT_RESOURCES_MANAGEMENT = '/application-management/resources',

    // 租户管理
    TENANT_MANAGEMENT = '/tenant-management',
    TENANT_MANAGEMENT_LIST = '/tenant-management/list',
    TENANT_MANAGEMENT_DETAIL = '/tenant-management/detail',
    TENANT_MANAGEMENT_FEEDBACK = '/tenant-management/feedback',

    // 运营管理
    OPERATIONS_MANAGEMENT = '/operations-management',

    // 预订管理
    OPERATIONS_MANAGEMENT_RESERVE_MANAGEMENT = '/operations-management/reserve-management',
    OPERATIONS_MANAGEMENT_RESERVE_MANAGEMENT_LIST = '/operations-management/reserve-management/list',
    OPERATIONS_MANAGEMENT_RESERVE_MANAGEMENT_DETAIL = '/operations-management/reserve-management/detail',

    // 退订列表
    OPERATIONS_MANAGEMENT_RETREAT_MANAGEMENT = '/operations-management/retreat-management',
    OPERATIONS_MANAGEMENT_RETREAT_MANAGEMENT_LIST = '/operations-management/retreat-management/list',
    OPERATIONS_MANAGEMENT_RETREAT_MANAGEMENT_DETAIL = '/operations-management/retreat-management/detail',
    OPERATIONS_MANAGEMENT_RETREAT_MANAGEMENT_CHECK = '/operations-management/retreat-management/check',

    // 租约管理
    OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT = '/operations-management/lease-management',
    OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_LEASE = '/operations-management/lease-management/lease',
    OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_RENEWAL = '/operations-management/lease-management/renewal',
    OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_EXCHANGE_HOUSE = '/operations-management/lease-management/exchange-house',
    OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_EXCHANGE_HOUSE_DETAIL = '/operations-management/lease-management/exchange-house-detail',
    OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHECK_OUT = '/operations-management/lease-management/check-out',
    OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHECK_OUT_DETAIL = '/operations-management/lease-management/check-out-detail',
    OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHECK_OUT_CHECK = '/operations-management/lease-management/check-out-check',
    OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHECK_OUT_CHECK_DETAIL = '/operations-management/lease-management/check-out-check-detail',
    OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHARGE = '/operations-management/lease-management/charge',
    OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHARGE_DETAIL = '/operations-management/lease-management/charge-detail',

    // 租约操作
    // 订单支付
    OPERATIONS_MANAGEMENT_ORDER_PAYMENT = '/operations-management/order-payment',
    // 账单支付
    OPERATIONS_MANAGEMENT_BILL_PAYMENT = '/operations-management/bill-payment',
    // 入住
    OPERATIONS_MANAGEMENT_HANDLE_CHECK_IN = '/operations-management/handle-check-in',
    OPERATIONS_MANAGEMENT_HANDLE_CHECK_IN_CHECK_IN_INFO = '/operations-management/handle-check-in/check-in-info',
    OPERATIONS_MANAGEMENT_HANDLE_CHECK_IN_HANDOVER_LIST = '/operations-management/handle-check-in/handover-list',
    // 预定
    OPERATIONS_MANAGEMENT_HANDLE_RESERVE = '/operations-management/handle-reserve',
    // 续租
    OPERATIONS_MANAGEMENT_HANDLE_RENEWAL = '/operations-management/handle-renewal',
    // 签约
    OPERATIONS_MANAGEMENT_HANDLE_CONTRACT_LEASE = '/operations-management/handle-contract-lease',
    // 退房
    OPERATIONS_MANAGEMENT_HANDLE_CHECK_OUT = '/operations-management/handle-check-out',
    // 换房
    OPERATIONS_MANAGEMENT_HANDLE_EXCHANGE_HOUSES = '/operations-management/handle-exchange-houses',
    OPERATIONS_MANAGEMENT_HANDLE_EXCHANGE_HOUSES_SUBMIT = '/operations-management/handle-exchange-houses/submit',
    OPERATIONS_MANAGEMENT_HANDLE_EXCHANGE_HOUSES_CHECKOUT_SUBMIT = '/operations-management/handle-exchange-houses/checkout-submit',
    // 批量签约
    OPERATIONS_MANAGEMENT_HANDLE_BATCH_SIGNING = '/operations-management/handle-batch-signing',

    // 水电抄表
    OPERATIONS_MANAGEMENT_HYDROPOWER_METER_READING = '/operations-management/hydropower-meter-reading',

    // 服务管理
    SERVICE_MANAGEMENT = '/service-management',
    SERVICE_MANAGEMENT_REPAIR = '/service-management/repair',
    SERVICE_MANAGEMENT_COMPLAINT = '/service-management/complaint',
    SERVICE_MANAGEMENT_BOOKING = '/service-management/booking',

    // 财务管理
    FINANCIAL_MANAGEMENT = '/financial-management',
    FINANCIAL_MANAGEMENT_PROCEED = '/financial-management/proceed',
    FINANCIAL_MANAGEMENT_ELECTRONIC_RECEIPT = '/financial-management/electronic-receipt',
    FINANCIAL_MANAGEMENT_ELECTRONIC_REFUND = '/financial-management/refund',
    FINANCIAL_MANAGEMENT_ELECTRONIC_REFUND_DETAIL = '/financial-management/refund-detail',
    FINANCIAL_MANAGEMENT_ELECTRONIC_REFUND_PAY = '/financial-management/refund-pay',
    FINANCIAL_MANAGEMENT_SUSPENSE_ACCOUNT = '/financial-management/suspense-account',
    FINANCIAL_MANAGEMENT_COUPON_LIST = '/financial-management/coupon-list',
    FINANCIAL_MANAGEMENT_COUPON_CODE_MANAGEMENT = '/financial-management/coupon-code-management',
    FINANCIAL_MANAGEMENT_ORDER_CHECK = '/financial-management/order-check',
    FINANCIAL_MANAGEMENT_ADAPAY_CHECK = '/financial-management/adapay-check',
    FINANCIAL_MANAGEMENT_WITHDRAWAL_CHECK = '/financial-management/withdrawal-check',
    FINANCIAL_MANAGEMENT_WITHDRAWAL_BRANCH = '/financial-management/withdrawal-branch',

    // 系统设置
    SYSTEM_SETTING = '/system-setting',
    SYSTEM_SETTING_BASE_SETTING = '/system-setting/base-setting',
    SYSTEM_SETTING_BASE_SETTING_PARAMETER = '/system-setting/base-setting/parameter',
    SYSTEM_SETTING_BASE_SETTING_PAYMENT = '/system-setting/base-setting/payment',
    SYSTEM_SETTING_BASE_SETTING_DATA_DICTIONARY = '/system-setting/base-setting/data-dictionary',
    SYSTEM_SETTING_BASE_SETTING_BASE_INFO = '/system-setting/base-setting/base-info',
    SYSTEM_SETTING_BASE_SETTING_BRANCH_LIST = '/system-setting/base-setting/branch-list',
    SYSTEM_SETTING_BASE_SETTING_BRANCH_DETAIL = '/system-setting/base-setting/branch-detail',

    // 日志管理
    SYSTEM_SETTING_LOG = '/system-setting/log',
    SYSTEM_SETTING_LOG_ABNORMAL = '/system-setting/log/abnormal',
    SYSTEM_SETTING_LOG_SLOG = '/system-setting/log/slog',
    SYSTEM_SETTING_LOG_OPERATION = '/system-setting/log/operation',

    // 组织设置
    SYSTEM_SETTING_ORGANIZATION_SETTING = '/system-setting/organization-setting',
    // 账号管理
    SYSTEM_SETTING_ORGANIZATION_SETTING_ACCOUNT_MANAGEMENT = '/system-setting/organization-setting/account-management',
    SYSTEM_SETTING_ORGANIZATION_SETTING_ROLE_MANAGEMENT = '/system-setting/organization-setting/role-management',
    SYSTEM_SETTING_ORGANIZATION_SETTING_ROLE_DEPARTMENT = '/system-setting/organization-setting/department',

    // 门店设置
    SYSTEM_SETTING_STORE_SETTING = '/system-setting/store-setting',
    SYSTEM_SETTING_STORE_SETTING_BUILDING_MANAGEMENT = '/system-setting/store-setting/building-management',
    SYSTEM_SETTING_STORE_SETTING_EDITOR_STORE_TEMPLATE = '/system-setting/store-setting/editor-store-template',
    SYSTEM_SETTING_STORE_SETTING_EDITOR_STORE_SINGLE_TEMPLATE = '/system-setting/store-setting/editor-store-single-template',
    SYSTEM_SETTING_STORE_SETTING_STORE_TEMPLATE_LIST = '/system-setting/store-setting/store-template-list',
    SYSTEM_SETTING_STORE_SETTING_STORE_SINGLE_TEMPLATE_LIST = '/system-setting/store-setting/store-single-template-list',
    SYSTEM_SETTING_STORE_SETTING_HANDOVER_ORDER = '/system-setting/store-setting/handover-order',
    SYSTEM_SETTING_STORE_SETTING_HOUSING_RESOURCES_ENTRY = '/system-setting/store-setting/housing-resources-entry',
    SYSTEM_SETTING_STORE_SETTING_HOUSING_RESOURCES_ENTRY_SUBMIT = '/system-setting/store-setting/housing-resources-entry-submit',
    SYSTEM_SETTING_STORE_SETTING_ROOM_LIST = '/system-setting/store-setting/room-list',
    SYSTEM_SETTING_STORE_SETTING_ROOM_OWNER = '/system-setting/store-setting/room-owner',
    SYSTEM_SETTING_STORE_SETTING_ROOM_OWNER_OPTION = '/system-setting/store-setting/room-owner-option',
    SYSTEM_SETTING_STORE_SETTING_ROOM_DEVICE = '/system-setting/store-setting/room-device',
    SYSTEM_SETTING_STORE_SETTING_ROOM_SINGLE = '/system-setting/store-setting/room-single',

    // 公司管理
    SYSTEM_SETTING_COMPANY_MANAGEMENT = '/system-setting/company-management',
    SYSTEM_SETTING_COMPANY_MANAGEMENT_COMPANY_LIST = '/system-setting/company-management/company-list',
    SYSTEM_SETTING_COMPANY_MANAGEMENT_COMPANY_FILE = '/system-setting/company-management/company-file',
    SYSTEM_SETTING_COMPANY_MANAGEMENT_COMPANY_DETAIL = '/system-setting/company-management/company-detail',

    // 财务配置
    SYSTEM_SETTING_FINANCIAL_SETTING = '/system-setting/financial-setting',
    SYSTEM_SETTING_FINANCIAL_SETTING_REVENUE_ITEMS = '/system-setting/financial-setting/revenue-items',
    SYSTEM_SETTING_FINANCIAL_SETTING_RATE = '/system-setting/financial-setting/rate',
    SYSTEM_SETTING_FINANCIAL_SETTING_STORES_PRICING = '/system-setting/financial-setting/stores-pricing',
    SYSTEM_SETTING_FINANCIAL_SETTING_DISCOUNT = '/system-setting/financial-setting/discount',
    SYSTEM_SETTING_FINANCIAL_SETTING_APPROVE = '/system-setting/financial-setting/approve',

    // 接口设置
    SYSTEM_SETTING_INTERFACE_SETTINGS = '/system-setting/interface-settings',
    SYSTEM_SETTING_INTERFACE_SETTINGS_INTERFACE_LIST = '/system-setting/interface-settings/interface-list',
    SYSTEM_SETTING_INTERFACE_SETTINGS_ALIPAY = '/system-setting/interface-settings/alipay',
    SYSTEM_SETTING_INTERFACE_SETTINGS_WECHAT = '/system-setting/interface-settings/wechat',
    SYSTEM_SETTING_INTERFACE_SETTINGS_INTELLIGENT_HARDWARE = '/system-setting/interface-settings/intelligent-hardware',
    SYSTEM_SETTING_INTERFACE_SETTINGS_ACCESS_CONTROL = '/system-setting/interface-settings/access-control',
    SYSTEM_SETTING_INTERFACE_SETTINGS_ADAPAY = '/system-setting/interface-settings/adapay',
    SYSTEM_SETTING_INTERFACE_SETTINGS_ADAPAY_OPTION = '/system-setting/interface-settings/adapay-option',

    // 移动端管理
    SYSTEM_SETTING_MOBILE__MANAGEMENT = '/system-setting/mobile-management',
    SYSTEM_SETTING_MOBILE__MANAGEMENT_BANNER = '/system-setting/mobile-management/banner',
    SYSTEM_SETTING_MOBILE__MANAGEMENT_BANNER_IMAGES = '/system-setting/mobile-management/banner-images',

    // 用户管理
    USER_MANAGEMENT = '/user-management',
    USER_MANAGEMENT_USER_LIST = '/user-management/user-list',
    USER_MANAGEMENT_CUSTOMER = '/user-management/customer',
    USER_MANAGEMENT_BLACKLIST = '/user-management/blacklist',
    USER_MANAGEMENT_USER_DETAIL = '/user-management/user-detail',

    // 报表管理
    REPORT_MANAGEMENT = '/report-management',
    REPORT_MANAGEMENT_CUSTOMER_REPORT = '/report-management/customer-report',
    REPORT_MANAGEMENT_CUSTOMER_REPORT_CUSTOMER_WALLET = '/report-management/customer-report/customer-wallet',
    REPORT_MANAGEMENT_CUSTOMER_REPORT_TENANT_LIST = '/report-management/customer-report/tenant-list',
    REPORT_MANAGEMENT_ERROR_REPORT = '/report-management/error-report',
    REPORT_MANAGEMENT_ERROR_REPORT_DOOR_LOCK_ERROR = '/report-management/error-report/door-lock-error',
    REPORT_MANAGEMENT_ERROR_REPORT_HYDROPOWER_ANOMALY = '/report-management/error-report/hydropower-anomaly',
    REPORT_MANAGEMENT_FINANCIAL_REPORT = '/report-management/financial-report',
    REPORT_MANAGEMENT_FINANCIAL_REPORT_ORDER_DETAILS = '/report-management/financial-report/order-details',
    REPORT_MANAGEMENT_FINANCIAL_REPORT_ADAPAY_FLOW = '/report-management/financial-report/adapay-details',
    REPORT_MANAGEMENT_FINANCIAL_REPORT_TRADE_DETAILS = '/report-management/financial-report/trade-details',
    REPORT_MANAGEMENT_FINANCIAL_REPORT_TRADE_FLOW = '/report-management/financial-report/trade-flow',
    REPORT_MANAGEMENT_FINANCIAL_REPORT_CONFIRMATION_DETAIL = '/report-management/financial-report/confirmation-detail',
    REPORT_MANAGEMENT_OPERATION_REPORT = '/report-management/operation-report',
    REPORT_MANAGEMENT_OPERATION_REPORT_AVERAGE_HOUSE_STATISTICS = '/report-management/operation-report/average-house-statistics',
    REPORT_MANAGEMENT_OPERATION_REPORT_HYDROPOWER_CONSUMPTION = '/report-management/operation-report/hydropower-consumption',
    REPORT_MANAGEMENT_OPERATION_REPORT_HYDROPOWER_DETAILS = '/report-management/operation-report/hydropower-details',
    REPORT_MANAGEMENT_OPERATION_REPORT_PRICE_APPROVAL_LOG = '/report-management/operation-report/price-approval-log',
    REPORT_MANAGEMENT_OPERATION_REPORT_RENTAL_RATE_STATISTICS = '/report-management/operation-report/rental-rate-statistics',

    // 房态中心
    ATRIAL_STATE_CENTER = '/atrial-state-center',
    ATRIAL_STATE_CENTER_CURRENT = '/atrial-state-center/current',
    ATRIAL_STATE_CENTER_FORWARD = '/atrial-state-center/forward',

    // 设备报警
    DEVICE_WARN = '/device-warn',
    DEVICE_WARN_SETTING = '/device-warn/setting',
    DEVICE_WARN_MESSAGE = '/device-warn/message',
    DEVICE_WARN_BLACKLIST = '/device-warn/blacklist'
}

export default Path;
