/*
 * @Author: mkRui
 * @Date: 2021-10-02 00:42:40
 * @LastEditTime: 2021-11-29 10:04:38
 */
import { BellOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { createHashHistory } from 'history';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { useInterval } from 'react-use';
import Path from 'ye-home/src/router/path';

import RemindStoreContext from '../store';
import Style from '../style/screen.module.less';

const Screen = () => {
    const store = useContext(RemindStoreContext);

    useInterval(() => {
        store.getData();
    }, 15 * 1000);

    const jump = () => {
        createHashHistory().push(Path.PERSONAL_CENTER_MESSAGE);
    };

    return (
        <div className={Style['screen']} onClick={jump}>
            <Badge count={store.data}>
                <BellOutlined />
            </Badge>
        </div>
    );
};

export default observer(Screen);
