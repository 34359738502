/*
 * @Author: mkRui
 * @Date: 2021-10-02 00:42:33
 * @LastEditTime: 2021-11-02 23:19:52
 */
import { FC } from 'react';
import HocUtils from 'ye-home/src/utils/hoc-utils';

import BranchStoreContext, { BranchStore } from './store';
import Branch from './view/main';

/**
 * 类型待待修正
 */
export default HocUtils<BranchStore, any>(
    BranchStoreContext,
    BranchStore
)(Branch);
