import * as types from './types';

type T = typeof types;

export function getTypesValue<N extends keyof T, V extends keyof T[N]>(
    name: N,
    value: V
) {
    // todo 优化判断方式
    if (typeof value === 'object' || typeof value === 'undefined') {
        return '';
    }
    // eslint-disable-next-line import/namespace
    return types[name][value];
}

export * from './enum-types';

export { types as default };
