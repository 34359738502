/*
 * @Author: mkRui
 * @Date: 2021-09-27 02:59:49
 * @LastEditTime: 2021-12-23 00:04:19
 */
import ClassNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { observer } from 'mobx-react';
import { FC, useContext, useEffect } from 'react';
import { RouteChildrenProps } from 'react-router';
import { HashRouter } from 'react-router-dom';

import Layout, { Aside, Header, Main } from 'ye-home/src/components/container';
import PageTab from 'ye-home/src/components/page-tab';
import Crumbs from 'ye-home/src/micro/crumbs';
import Config from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
import RouterComponent from 'ye-home/src/router/route-component';
import RootContext from 'ye-home/src/stores/root-context';
import { MenuModeEnum } from 'ye-home/src/types';
import useEventEffect from 'ye-home/src/hook/use-event-effect';

import LayoutAside from './container/aside';
import LayoutHeader from './container/header';
import LayoutMain from './container/main';

import Styles from './style/app.module.less';

const App: FC<RouteChildrenProps> = (props) => {
    const store = useContext(RootContext);

    const mainClass = () => {
        return ClassNames(`${Styles['main']}`, {
            [`${Styles[`main--aside`]}`]:
                store.menuMode !== MenuModeEnum.MODE_HEADER,
            [`${Styles[`main--collapsed`]}`]: store.collapsed
        });
    };

    const getDate = async () => {
        await store.init();
        await store.getUserInfo();
        if (store.userInfo.id) {
            await store.getMenuList();
        }
    };

    useEventEffect(() => {
        getDate();
    });

    return (
        <Layout style={{ width: '100%' }}>
            <Header>
                <LayoutHeader />
            </Header>
            <Layout style={{ height: 'calc(100vh - 50px)', minHeight: 'auto' }}>
                {store.menuMode !== MenuModeEnum.MODE_HEADER && (
                    <Aside collapsed={store.collapsed}>
                        <LayoutAside />
                    </Aside>
                )}
                <Main className={mainClass()}>
                    <Scrollbars
                        autoHide
                        renderTrackHorizontal={({ style, ...props }) => (
                            <div
                                {...props}
                                style={{
                                    ...style,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    zIndex: 20
                                }}
                            />
                        )}
                    >
                        <div className={Styles[`main--content`]}>
                            <PageTab />
                            <Crumbs />
                            <LayoutMain>
                                <HashRouter>
                                    <RouterComponent
                                        config={Config}
                                        baseRouter={Path.BASE}
                                        redirect={Path.DASHBOARD}
                                    />
                                </HashRouter>
                            </LayoutMain>
                        </div>
                    </Scrollbars>
                </Main>
            </Layout>
        </Layout>
    );
};

export default observer(App);
