/*
 * @Author: mkRui
 * @Date: 2021-11-10 22:49:27
 * @LastEditTime: 2021-11-10 22:49:45
 */
// 消息模块 弹出式
import { message, Modal, notification } from 'antd';

type MessagesType = 'success' | 'info' | 'warning' | 'error';

export function messages(type: MessagesType, title: String, content?: String) {
    notification[type]({
        message: title,
        description: content
    });
}

export function toast(type: MessagesType, title: String) {
    message[type](title);
}
