/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Store } from 'mor-request';
import { createContext } from 'react';
import axios from 'ye-home/src/api';

import { Api } from '../api';
import { API } from '../types';
import FeedbackCategoryList from './feedback-category-list';

export class AddFeedbackStore extends Store<Api> {
    public feedbackCategoryList: FeedbackCategoryList;

    constructor() {
        super(new Api(axios));
        this.feedbackCategoryList = new FeedbackCategoryList(axios);
    }

    public sendFeedback(params: API.AddFeedback.Params) {
        return this.api.addFeedback(params);
    }
}

const AddFeedbackContext = createContext<AddFeedbackStore>(
    {} as AddFeedbackStore
);

export default AddFeedbackContext;
