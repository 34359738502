/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-26 00:37:43
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.ATRIAL_STATE_CENTER_CURRENT,
        components: import('../views/current'),
        meta: {
            title: '当前房态图'
        }
    },
    {
        path: Path.ATRIAL_STATE_CENTER_FORWARD,
        components: import('../views/forward'),
        meta: {
            title: '远期房态图'
        }
    }
];

export default config;
