/*
 * @Author: mkRui
 * @Date: 2021-10-10 15:22:19
 * @LastEditTime: 2021-11-10 22:43:15
 */
import { Request } from 'mor-request';

import { API } from '../types/api';

export class Api extends Request {
    // 登录
    public login(params: API.Login.Params) {
        return this.post<API.Login.Data>(`/admin/auth/login`, params);
    }

    // 选择所有租户
    public authList() {
        return this.get<API.AuthList.Data[]>('/admin/auth/loginTenantList');
    }
}
