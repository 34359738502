/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-28 00:40:23
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.SYSTEM_SETTING_MOBILE__MANAGEMENT_BANNER,
        components: import('../views/banner-list'),
        meta: {
            title: '广告管理'
        }
    },
    {
        path: Path.SYSTEM_SETTING_MOBILE__MANAGEMENT_BANNER_IMAGES,
        components: import('../views/banner-img'),
        meta: {
            title: '广告位图片列表',
            routerPosition: Path.SYSTEM_SETTING_MOBILE__MANAGEMENT_BANNER
        }
    }
];

export default config;
