/*
 * @Author: mkRui
 * @Date: 2021-10-30 11:28:03
 * @LastEditTime: 2021-11-28 21:10:38
 */
interface ListType {
    id: number;
    parentId: number;
    children?: ListType[];
}

interface TreeType {
    list: ListType[];
}

export class Tree implements TreeType {
    public list: ListType[];

    public constructor(dataSource: any) {
        this.list = dataSource;
    }

    // 根据parentId 初始化 tree
    public initTree(parentId: number) {
        return this.loop(parentId);
    }

    public loop(parentId: any) {
        const treeList: any = [];
        const len = this.list.length;
        for (let i = 0; i < len; i++) {
            const item = this.list[i];
            if (item.parentId === parentId) {
                const loop = this.loop(item.id);
                item.children = loop.length ? loop : null;
                treeList.push(item);
            }
        }
        return treeList;
    }
}

// 查找 Tree id
export function Search(config: any, target: string) {
    const arr: any = [];

    const find = config.find((elem: any) => elem.menuUrl === target);

    if (find) {
        arr.unshift(find.id);
        deep(config, find.parentId);
    }

    function deep(config: any, id: number) {
        const deepFind = config.find((elem: any) => elem.id === id);

        if (deepFind) {
            arr.unshift(deepFind.id);
            deep(config, deepFind.parentId);
        }
    }

    return arr;
}

// 查找 Tree name
export function SearchName(config: any, target: string) {
    const arr: any = [];

    const find = config.find((elem: any) => elem.menuUrl === target);

    if (find) {
        arr.unshift(find.menuName);
        deep(config, find.parentId);
    }

    function deep(config: any, id: number) {
        const deepFind = config.find((elem: any) => elem.id === id);

        if (deepFind) {
            arr.unshift(deepFind.menuName);
            deep(config, deepFind.parentId);
        }
    }

    return arr;
}

export default {
    Tree,
    Search
};
