import { Request } from 'mor-request';

import { API } from '../types';

export class Api extends Request {
    // 获取问题反馈类型列表
    public getFeedbackCategoryList() {
        return this.post<API.FeedbackCategoryList.Data[]>(
            '/admin/common/feedbackCategoryList',
            {}
        );
    }

    // 获取问题反馈类型列表
    public addFeedback(params: API.AddFeedback.Params) {
        return this.post<null>('/admin/common/feedbackAdd', params);
    }
}
