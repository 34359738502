/*
 * @Author: mkRui
 * @Date: 2021-10-03 12:14:43
 * @LastEditTime: 2021-10-03 16:50:55
 */
import React, { FC } from 'react';

interface IconProps {
    name: string;
    style?: React.CSSProperties;
}

const MorIcon: FC<IconProps> = (props) => {
    const { name, ...otherProps } = props;
    const Icon = require('@ant-design/icons')[name];
    return <Icon {...otherProps} />;
};

export default MorIcon;
