/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-12-26 19:37:24
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    // 租约管理
    {
        path: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT,
        components: import('../lease-management'),
        meta: {
            title: '租约管理'
        }
    },
    // 预订管理
    {
        path: Path.OPERATIONS_MANAGEMENT_RESERVE_MANAGEMENT,
        components: import('../reserve-management'),
        meta: {
            title: '预订管理'
        }
    },
    // 退订管理
    {
        path: Path.OPERATIONS_MANAGEMENT_RETREAT_MANAGEMENT,
        components: import('../retreat-management'),
        meta: {
            title: '退房管理'
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_ORDER_PAYMENT,
        components: import('../order-payment'),
        meta: {
            title: '订单支付',
            routerPosition: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_LEASE
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_BILL_PAYMENT,
        components: import('../bill-payment'),
        meta: {
            title: '账单支付',
            routerPosition: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_LEASE
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_HANDLE_RESERVE,
        components: import('../handle-reserve'),
        meta: {
            title: '预订',
            routerPosition: Path.OPERATIONS_MANAGEMENT_RESERVE_MANAGEMENT_LIST
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_HANDLE_BATCH_SIGNING,
        components: import('../handle-batch-signing'),
        meta: {
            title: '批量签约',
            routerPosition: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_LEASE
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_HANDLE_RENEWAL,
        components: import('../handle-renewal'),
        meta: {
            title: '续租',
            routerPosition: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_LEASE
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_HANDLE_CHECK_IN,
        components: import('../handle-check-in'),
        meta: {
            title: '入住',
            routerPosition: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_LEASE
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_HANDLE_CHECK_OUT,
        components: import('../handle-check-out'),
        meta: {
            title: '退房',
            routerPosition:
                Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHECK_OUT
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_HANDLE_EXCHANGE_HOUSES,
        components: import('../handle-exchange-houses'),
        meta: {
            title: '换房',
            routerPosition:
                Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_EXCHANGE_HOUSE
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_HANDLE_CONTRACT_LEASE,
        components: import('../handle-contract-lease'),
        meta: {
            title: '签约',
            routerPosition: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_LEASE
        }
    },
    // 水电抄表
    {
        path: Path.OPERATIONS_MANAGEMENT_HYDROPOWER_METER_READING,
        components: import('../hydropower-meter-reading'),
        meta: {
            title: '水电抄表'
        }
    }
];

export default config;
