/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-14 10:13:31
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.REPORT_MANAGEMENT_FINANCIAL_REPORT_TRADE_DETAILS,
        components: import('../views/trade-details'),
        meta: {
            title: '营收统计'
        }
    },
    {
        path: Path.REPORT_MANAGEMENT_FINANCIAL_REPORT_TRADE_FLOW,
        components: import('../views/trade-flow'),
        meta: {
            title: '交易流水'
        }
    },
    {
        path: Path.REPORT_MANAGEMENT_FINANCIAL_REPORT_CONFIRMATION_DETAIL,
        components: import('../views/confirmation-details'),
        meta: {
            title: '证明详情'
        }
    },
    {
        path: Path.REPORT_MANAGEMENT_FINANCIAL_REPORT_ORDER_DETAILS,
        components: import('../views/order-details'),
        meta: {
            title: '流水明细'
        }
    },
    {
        path: Path.REPORT_MANAGEMENT_FINANCIAL_REPORT_ADAPAY_FLOW,
        components: import('../views/adapay-flow'),
        meta: {
            title: '门店划账'
        }
    }
];

export default config;
