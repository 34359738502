/*
 * @Author: mkRui
 * @Date: 2021-09-26 10:16:38
 * @LastEditTime: 2021-12-23 00:13:30
 */
import { Col, Form, Input, Row } from 'antd';
import { createHashHistory } from 'history';
import { FC, useContext, useEffect, useState } from 'react';
import RootContext from 'ye-home/src/stores/root-context';
import morStorage from 'ye-home/src/utils/local-storage';

import context from '../store';
import { API } from '../types/api';
import ImgVCode from './img-vcode';
import Button from './login-button';

const Item = Form.Item;

interface LoginFormType {
    onSuccess: () => void;
}

const LoginForm: FC<LoginFormType> = (props) => {
    const { onSuccess } = props;

    const [form] = Form.useForm();

    const loginStore = useContext(context);

    const store = useContext(RootContext);

    const [time, setTime] = useState(Number(new Date()));

    const handleSubmit = async (value: API.Login.Params) => {
        const res = await loginStore.login(value);
        const { username } = value;
        const tenId = loginStore.params.tenId;
        const appName = loginStore.authList.find(
            (item) => item.id === tenId
        )?.appName;
        if (res.code === 0) {
            morStorage.setItem('userInfo', { username, tenId, appName });
            window.localStorage.setItem('token', res.data.accessToken);
            await store.getMenuList();
            onSuccess();
            const firstRoute = store.menuInfo.find(
                (item) => item.parentId === store.menuInfo[0].id
            );
            firstRoute?.menuUrl &&
                createHashHistory().replace(firstRoute.menuUrl);
        }
        setTime(Number(new Date()));
    };

    const init = () => {
        // todo 封装本地存储方法
        const user = morStorage.getItem('userInfo');
        if (!user) return false;
        const { username, tenId } = JSON.parse(user);
        loginStore.$setParams({
            username,
            tenId
        });
        form.setFieldsValue({
            username
        });
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <div style={{ width: '300px' }}>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Item name="username" validateTrigger="onBlur">
                    <Input
                        className="input"
                        style={{
                            height: '42px'
                        }}
                        placeholder="用户名"
                    />
                </Item>
                <Item name="password" validateTrigger="onBlur">
                    <Input.Password
                        placeholder="密码"
                        type="password"
                        className="input"
                        style={{
                            height: '42px'
                        }}
                    />
                </Item>
                <Item>
                    <Row>
                        <Col span={16}>
                            <Item
                                name="vercode"
                                noStyle
                                validateTrigger="onBlur"
                            >
                                <Input
                                    className="input"
                                    style={{
                                        height: '42px'
                                    }}
                                    placeholder="验证码"
                                    // addonAfter={<ImgVCode time={time} />}
                                />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <ImgVCode time={time} />
                        </Col>
                    </Row>
                </Item>
                <Item noStyle>{props.children || ''}</Item>
                <Item style={{ marginBottom: '10px' }}>
                    <Button />
                </Item>
            </Form>
        </div>
    );
};

export default LoginForm;
