/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-12-27 02:26:47
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.OPERATIONS_MANAGEMENT_HANDLE_CHECK_IN_CHECK_IN_INFO,
        components: import('../views/check-in-info'),
        meta: {
            title: '办理入住',
            routerPosition: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_LEASE
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_HANDLE_CHECK_IN_HANDOVER_LIST,
        components: import('../views/handover-list'),
        meta: {
            title: '交接单',
            routerPosition: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_LEASE
        }
    }
];

export default config;
