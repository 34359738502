/*
 * @Author: mkRui
 * @Date: 2021-10-07 15:08:37
 * @LastEditTime: 2021-12-16 12:22:21
 */
import { observer } from 'mobx-react';
import { createContext, FC } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import Path from 'ye-home/src/router/path';

import LoginFc from 'ye-home/src/micro/auth';
import AppFc from './app';

interface AppContextProps {
    collapsed: boolean;
    setCollapsed: Function;
}

export const AppContext = createContext<AppContextProps>({
    collapsed: false,
    setCollapsed: () => null
});

const App: FC = (props) => {
    document.body.setAttribute('mor-theme', 'light');

    return (
        <HashRouter>
            <Switch>
                <Route
                    exact
                    path={Path.BASE}
                    render={() => <Redirect to={Path.LOGIN_CHECK} />}
                />
                <Route key={Path.LOGIN} path={Path.LOGIN} component={LoginFc} />
                <Route key={Path.BASE} path={Path.BASE} component={AppFc} />
            </Switch>
        </HashRouter>
    );
};

export default observer(App);
