/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-26 18:05:54
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.SYSTEM_SETTING_COMPANY_MANAGEMENT_COMPANY_LIST,
        components: import('../view/company-list'),
        meta: {
            title: '公司列表'
        }
    },
    {
        path: Path.SYSTEM_SETTING_COMPANY_MANAGEMENT_COMPANY_FILE,
        components: import('../view/company-file'),
        meta: {
            title: '公司模版',
            routerPosition: Path.SYSTEM_SETTING_COMPANY_MANAGEMENT_COMPANY_LIST
        }
    },
    {
        path: Path.SYSTEM_SETTING_COMPANY_MANAGEMENT_COMPANY_DETAIL,
        components: import('../view/company-detail'),
        meta: {
            title: '公司详情',
            routerPosition: Path.SYSTEM_SETTING_COMPANY_MANAGEMENT_COMPANY_LIST
        }
    }
];

export default config;
