/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-12-06 23:15:03
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.REPORT_MANAGEMENT_OPERATION_REPORT_AVERAGE_HOUSE_STATISTICS,
        components: import('../views/average-house-statistics'),
        meta: {
            title: '平均房价统计'
        }
    },
    {
        path: Path.REPORT_MANAGEMENT_OPERATION_REPORT_HYDROPOWER_CONSUMPTION,
        components: import('../views/hydropower-consumption'),
        meta: {
            title: '水电每日用量'
        }
    },
    {
        path: Path.REPORT_MANAGEMENT_OPERATION_REPORT_HYDROPOWER_DETAILS,
        components: import('../views/hydropower-details'),
        meta: {
            title: '水电读表明细'
        }
    },
    {
        path: Path.REPORT_MANAGEMENT_OPERATION_REPORT_PRICE_APPROVAL_LOG,
        components: import('../views/price-approval-log'),
        meta: {
            title: '价格审批日志'
        }
    },
    {
        path: Path.REPORT_MANAGEMENT_OPERATION_REPORT_RENTAL_RATE_STATISTICS,
        components: import('../views/rental-rate-statistics'),
        meta: {
            title: '出租率统计'
        }
    }
];

export default config;
