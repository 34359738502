/*
 * @Author: mkRui
 * @Date: 2021-09-26 10:16:38
 * @LastEditTime: 2021-11-17 02:02:22
 */
import { Dropdown, Menu, Select } from 'antd';
import ClassNames from 'classnames';
import { observer } from 'mobx-react';
import { useContext, useEffect, useState } from 'react';
import morStorage from 'ye-home/src/utils/local-storage';

import Form from '../components/login-form';
import { iconArrowSvg } from '../icon/svg-icons';
import context from '../store';
import Styles from '../style/views/login.module.less';

const Login = () => {
    const loginStore = useContext(context);

    const [show, setShow] = useState(morStorage.searchItem('userInfo'));

    const [logo, setLogo] = useState(morStorage.getItem('logo'));

    const [branchName, setBranchName] = useState<string>();

    const [up, setUp] = useState(false);

    const { authList } = loginStore;

    const userInfo = morStorage.getItem('userInfo', true);

    const getBranch = (tenId: number) => {
        return authList.find((item) => item.id === tenId);
    };

    const handleSelectAuth = ({ key }: { key: string }) => {
        loginStore.selectAuth(Number(key));
        const branch = getBranch(Number(key));
        setBranchName(branch?.name);
        if (branch?.logo) {
            setLogo(branch?.logo);
        }
    };

    const menu = (
        <Menu onClick={handleSelectAuth}>
            {authList.map((item) => (
                <Menu.Item key={item.id}>{item.name}</Menu.Item>
            ))}
        </Menu>
    );

    const loginStatus = ClassNames(`${Styles['login']}`, {
        [`${Styles['login--show']}`]: !show
    });

    const branchStatus = ClassNames(`${Styles['branch']}`, {
        [`${Styles['branch--show']}`]: show
    });

    const containerStatus = ClassNames(`${Styles['container']}`, {
        [`${Styles['container--show']}`]: show
    });

    const dialogStatus = ClassNames(`${Styles['dialog']}`, {
        [`${Styles['dialog--show']}`]: show
    });

    const upStatus = ClassNames(`${Styles['icon']}`, {
        [`${Styles['icon--up']}`]: up
    });

    const init = async () => {
        const res = await loginStore.getAuthList();
        if (res?.length) {
            if (!morStorage.searchItem('userInfo')) {
                const branch = res[0];
                setBranchName(branch.name);
                loginStore.selectAuth(branch.id);
                if (branch.logo) {
                    setLogo(branch.logo);
                }
            } else {
                setBranchName(
                    res.find((item) => item.id === userInfo.tenId)?.name || ''
                );
            }
        }
    };

    const handleSwitch = () => {
        setShow(!show);
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <div className={`${Styles['login-container']}`}>
            <div className={`${Styles['login-content']}`}>
                <div className={containerStatus}>
                    <div className={loginStatus}>
                        <h3 className={Styles['branch__title']}>
                            请选择登录主体
                        </h3>
                        <img width={117} height={117} src={logo} alt="" />
                        <Dropdown
                            overlay={menu}
                            placement="bottomLeft"
                            trigger={['click']}
                            arrow={false}
                            onVisibleChange={() => setUp(!up)}
                        >
                            <div className={Styles['branch__btn']}>
                                <span>{branchName}</span>
                                <i
                                    className={upStatus}
                                    dangerouslySetInnerHTML={{
                                        __html: iconArrowSvg({
                                            width: 16,
                                            height: 16
                                        })
                                    }}
                                />
                            </div>
                        </Dropdown>
                    </div>
                    <div className={branchStatus}>
                        <h3 className={Styles['branch__title']}>登录</h3>
                        <Form
                            onSuccess={() => morStorage.setItem('logo', logo)}
                        />
                    </div>
                </div>
                {/* 遮罩 */}
                <div className={dialogStatus}>
                    {show && (
                        <img
                            width={117}
                            height={117}
                            src={logo}
                            style={{ marginBottom: '20px' }}
                            alt=""
                        />
                    )}
                    <button
                        className={Styles['dialog__button']}
                        onClick={handleSwitch}
                    >
                        {show ? branchName : '确认'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default observer(Login);
