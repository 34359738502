/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { AxiosInstance } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { Store } from 'mor-request';

import { Api } from '../api';
import { API } from '../types';

export default class FeedbackCategoryList extends Store<Api> {
    public list: API.FeedbackCategoryList.Data[] = [];

    constructor(axios: AxiosInstance) {
        super(new Api(axios));
        makeObservable(this, {
            list: observable,
            setList: action
        });
    }

    public setList(list: API.FeedbackCategoryList.Data[]) {
        this.list = list;
    }

    public async getList() {
        const res = await this.api.getFeedbackCategoryList();
        if (res.code === 0) this.setList(res.data);
    }
}
