/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-12-11 22:39:28
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_LEASE,
        components: import('../views/lease-list'),
        meta: {
            title: '租约列表'
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_EXCHANGE_HOUSE,
        components: import('../views/exchange-house-list'),
        meta: {
            title: '换房列表'
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_EXCHANGE_HOUSE_DETAIL,
        components: import('../views/exchange-house-detail'),
        meta: {
            title: '换房详情',
            routerPosition:
                Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_EXCHANGE_HOUSE
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHECK_OUT,
        components: import('../views/check-out-list'),
        meta: {
            title: '退房列表'
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHECK_OUT_DETAIL,
        components: import('../views/check-out-detail'),
        meta: {
            title: '退房详情',
            routerPosition:
                Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHECK_OUT
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHECK_OUT_CHECK,
        components: import('../views/check-out-check-list'),
        meta: {
            title: '退房审批'
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHECK_OUT_CHECK_DETAIL,
        components: import('../views/check-out-check-detail'),
        meta: {
            title: '退房审批详情',
            routerPosition:
                Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHECK_OUT_CHECK
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHARGE,
        components: import('../views/charge-list'),
        meta: {
            title: '挂帐列表'
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHARGE_DETAIL,
        components: import('../views/charge-detail'),
        meta: {
            title: '挂帐详情',
            routerPosition: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_CHARGE
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_LEASE_MANAGEMENT_RENEWAL,
        components: import('../views/renewal-list'),
        meta: {
            title: '续租列表'
        }
    }
];

export default config;
