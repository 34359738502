/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-06 18:44:47
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.USER_MANAGEMENT_BLACKLIST,
        components: import('../views/blacklist'),
        meta: {
            title: '黑名单'
        }
    },
    {
        path: Path.USER_MANAGEMENT_USER_LIST,
        components: import('../views/user-list'),
        meta: {
            title: '用户账号'
        }
    },
    {
        path: Path.USER_MANAGEMENT_CUSTOMER,
        components: import('../views/customer'),
        meta: {
            title: '大客户'
        }
    }
];

export default config;
