import { Spin } from 'antd';

const LoadingFc = () => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '500px'
            }}
        >
            <Spin spinning={true} size="large" tip="页面加载中...." />
        </div>
    );
};

export default LoadingFc;
