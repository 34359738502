/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-06 14:59:02
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.APPLICATION_MANAGEMENT_LIST,
        components: import('../view/application'),
        meta: {
            title: '应用管理'
        }
    },
    {
        path: Path.APPLICATION_MANAGEMENT_RESOURCES_MANAGEMENT,
        components: import('../view/resources'),
        meta: {
            title: '资源管理'
        }
    }
];

export default config;
