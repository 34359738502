/*
 * @Author: mkRui
 * @Date: 2021-09-26 10:16:38
 * @LastEditTime: 2021-09-27 03:53:09
 */
import HocUtils from 'ye-home/src/utils/hoc-utils';

import context, { AuthStore } from './store';
import Main from './views/login';

export default HocUtils<AuthStore, {}>(context, AuthStore)(Main);
