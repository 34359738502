/*
 * @Author: mkRui
 * @Date: 2021-09-28 16:05:04
 * @LastEditTime: 2021-12-22 23:22:20
 */

import { SettingOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { createHashHistory } from 'history';
import { FC, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useList } from 'react-use';
import Path from 'ye-home/src/router/path';
import withRouterEnhance, {
    MetaProps
} from 'ye-home/src/utils/with-router-enhance';
import morFoundationStorage from 'ye-home/src/utils/foundation-local-storage';
import eventDispatch from 'ye-home/src/utils/event-dispatch';

import Tab from '../components/tab';
import Styles from '../styles/main.module.less';

interface TabItem {
    title: string;
    basePath: string;
    path: string;
}

const PageTab: FC<MetaProps> = (props) => {
    const { history, base } = props;

    const [list, { push, clear, removeAt, set }] = useList<TabItem>([]);

    const container = useRef<any>();

    const tabContainer = useRef<any>();

    const timer = useRef<any>();

    const [path, setPath] = useState('');

    const { location } = history;

    const getPathIndex = (url: string) => {
        const index = list.findIndex((item) => item.path === url);
        return index;
    };

    const goRight = () => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            const c = container.current;
            const t = tabContainer.current;
            c.scrollBy(t.offsetWidth, 0);
        }, 0);
    };

    useEffect(() => {
        if (base) {
            const index = getPathIndex(location.pathname + location.search);
            const tab: TabItem = {
                title: base.meta?.title || '',
                basePath: base.path,
                path: location.pathname + location.search
            };
            if (index === -1) {
                push(tab);
                goRight();
            }
            setPath(location.pathname + location.search);
        }
    }, [location.pathname, location.search]);

    useEffect(() => {
        eventDispatch.once('complete', (url: string) => {
            handleClose(url);
        });
        eventDispatch.once('changeBranch', () => {
            morFoundationStorage.deleteItem(Path.ATRIAL_STATE_CENTER_CURRENT);
        });
    }, [list]);

    const handleClose = (url: string) => {
        const index = getPathIndex(url);
        removeAt(index);
        if (index === 0 && list.length === 1) {
            createHashHistory().push(Path.ATRIAL_STATE_CENTER_CURRENT);
        } else if (url.includes(location.pathname)) {
            const path =
                (list[index - 1] || list[index + 1])?.path ||
                Path.ATRIAL_STATE_CENTER_CURRENT;
            createHashHistory().push(path);
        }
    };

    const handleScroll = (e: any) => {
        const t = tabContainer.current;
        const c = container.current;
        if (e.nativeEvent.deltaY <= 0) {
            /* scrolling up */
            c.scrollTo(Math.max(c.scrollLeft - 10, 0), 0);
        } else {
            /* scrolling down */
            c.scrollTo(Math.min(c.scrollLeft + 10, t.offsetWidth), 0);
        }
    };

    const handleClearAll = () => {
        clear();
        history.push(Path.ATRIAL_STATE_CENTER_CURRENT);
        morFoundationStorage.refresh();
    };

    const handleCloseOther = (path: string) => {
        console.log(list, path);
        set(list.filter((item) => item.path === path));
        list.forEach((item) => {
            if (item.path !== path) {
                morFoundationStorage.deleteItem(item.path);
            }
        });
    };

    const click = async ({ key }: { key: string }) => {
        switch (key) {
            case 'closeAll':
                handleClearAll();
                break;
            case 'closeOther':
                handleCloseOther(path);
                break;
        }
    };

    const MenuList = (
        <Menu onClick={click}>
            <Menu.Item key="closeAll">关闭所有</Menu.Item>
            <Menu.Item key="closeOther">关闭其他</Menu.Item>
        </Menu>
    );

    return (
        <div className={Styles['container']}>
            <div
                ref={container}
                className={Styles['page-tab__container']}
                onWheel={(e) => handleScroll(e)}
            >
                <div ref={tabContainer} className={Styles['page-tab__context']}>
                    {list.map((item: TabItem, index: number) => (
                        <Tab
                            key={item.path}
                            title={item.title || ''}
                            path={item.path}
                            active={path === item.path}
                            onClose={(url: string) => handleClose(url)}
                        />
                    ))}
                </div>
            </div>
            <Dropdown trigger={['click']} overlay={MenuList}>
                <button className={Styles['button']}>
                    <SettingOutlined />
                </button>
            </Dropdown>
        </div>
    );
};

// todo 需要改进
export default withRouter(withRouterEnhance(PageTab));
