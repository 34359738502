/*
 * @Author: mkRui
 * @Date: 2021-10-10 15:22:19
 * @LastEditTime: 2021-12-19 15:19:53
 */
import { Request } from 'mor-request';

import { API } from '../types';
export default class Api extends Request {
    // 获取我的门店列表
    public getBranchList() {
        return this.get<API.BranchList.Data[]>('/admin/common/getMyBranchList');
    }

    // 获取菜单列表
    public changeBranch(params: API.ChangeBranch.Params) {
        return this.post('/admin/common/changeBranch', params);
    }
}
