/*
 * @Author: mkRui
 * @Date: 2021-10-17 11:34:54
 * @LastEditTime: 2021-10-20 08:51:59
 */

import Styles from '../style/components/login-button.module.less';
const Button = () => {
    return (
        <button type="submit" className={Styles['btn']}>
            登录
            <span />
            <span />
            <span />
            <span />
        </button>
    );
};

export default Button;
