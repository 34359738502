/*
 * @Author: mkRui
 * @Date: 2021-10-10 15:22:19
 * @LastEditTime: 2021-11-28 23:23:13
 */
import { Request } from 'mor-request';

import { API } from '../types';
export default class Api extends Request {
    // 消息数量
    public getMessageCount() {
        return this.get<API.UserMessageCount.Data>(
            '/admin/common/userMessageCount'
        );
    }
}
