/*
 * @Author: mkRui
 * @Date: 2021-09-28 16:05:04
 * @LastEditTime: 2021-12-22 23:22:20
 */

import { observer } from 'mobx-react';
import { FC, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Context from 'ye-home/src/stores/root-context';
import { SearchName } from 'ye-home/src/utils/tree';
import withRouterEnhance, {
    MetaProps
} from 'ye-home/src/utils/with-router-enhance';

import Styles from '../styles/main.module.less';

interface TabItem {
    title: string;
    basePath: string;
    path: string;
}

const PageTab: FC<MetaProps> = (props) => {
    const { history, base } = props;

    const store = useContext(Context);

    const [path, setPath] = useState<string[]>([]);

    const { location } = history;

    useEffect(() => {
        const search: any = SearchName(store.menuInfo, location.pathname);
        if (search.length) {
            setPath(search);
        } else {
            setPath([base?.meta?.title || '']);
        }
    }, [location.pathname, store.menuInfo]);

    return (
        <div className={Styles['container']}>
            {path.map((item, index) => (
                <span key={index}>
                    {item}
                    {index !== path.length - 1 && <i>/</i>}
                </span>
            ))}
        </div>
    );
};

// todo 需要改进
export default withRouter(withRouterEnhance(observer(PageTab)));
