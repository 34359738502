/*
 * @Author: mkRui
 * @Date: 2021-09-28 10:31:36
 * @LastEditTime: 2021-10-02 22:26:13
 */
import ClassNames from 'classnames';
import { FC, useContext, useEffect } from 'react';

import Style from '../style/aside.module.less';
import { AsideType } from '../types';
import { LayoutContext } from './main';

const Side: FC<AsideType> = (props) => {
    const { children, collapsed } = props;

    const { sideHook } = useContext(LayoutContext);

    const classN = ClassNames(`${Style['aside']}`, {
        [`${Style['aside_collapsed']}`]: collapsed
    });

    useEffect(() => {
        sideHook.showSide(true);
    }, []);

    return <div className={classN}>{children}</div>;
};

export default Side;
