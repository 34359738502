/*
 * @Author: mkRui
 * @Date: 2021-10-01 22:13:15
 * @LastEditTime: 2021-10-24 17:25:47
 */

import ClassNames from 'classnames';
import { FC } from 'react';

import Style from '../style/avatar.module.less';
import { AvatarProps } from '../types';

const Avatar: FC<AvatarProps> = (props) => {
    const { size, shadow, url } = props;

    const classN = ClassNames(`${Style['avatar']}`, {
        [`${Style[`avatar--${size}`]}`]: !!size,
        [`${Style['avatar--shadow']}`]: shadow
    });

    return (
        <div className={classN}>
            <img
                src={url ? url : require('../icon/user.svg').default}
                width="40"
                height="40"
                alt=""
            />
        </div>
    );
};
export default Avatar;
