import { Form, Input, message, Modal, Select } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useContext, useEffect } from 'react';
import Upload from 'ye-home/src/components/upload';
import RootContext from 'ye-home/src/stores/root-context';
import HocUtils from 'ye-home/src/utils/hoc-utils';

import AddFeedbackContext, { AddFeedbackStore } from '../store';
import { API } from '../types';
const { Item } = Form;
const Option = Select;

interface ModalContainer {
    onClose: () => void;
}

const AddFeedbackModal: FC<ModalContainer> = (props) => {
    const { onClose } = props;

    const [form] = Form.useForm();
    const handleOk = () => {
        form.validateFields().then(async (data) => {
            const res = await feedbackStore.sendFeedback(data);
            if (res.code === 0) {
                message.success('操作成功');
                onClose();
            }
        });
    };

    const feedbackStore = useContext(AddFeedbackContext);
    const feedbackCategoryList = feedbackStore['feedbackCategoryList'];

    useEffect(() => {
        feedbackCategoryList.getList();
    }, []);

    return (
        <Modal
            title="提交问题反馈"
            visible={true}
            onOk={handleOk}
            onCancel={onClose}
        >
            <Form form={form} layout="vertical">
                <Item
                    label="问题反馈类型"
                    name="categoryCode"
                    required
                    rules={[
                        {
                            required: true,
                            message: '不能为空'
                        }
                    ]}
                >
                    <Select placeholder="请选择问题反馈类型">
                        {feedbackCategoryList.list.map(
                            (item: API.FeedbackCategoryList.Data) => {
                                return (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                );
                            }
                        )}
                    </Select>
                </Item>
                <Item
                    label="问题反馈名称"
                    name="categoryName"
                    required
                    rules={[
                        {
                            required: true,
                            message: '不能为空'
                        }
                    ]}
                >
                    <Input placeholder="请输入问题反馈名称" />
                </Item>
                <Item
                    label="问题描述"
                    name="content"
                    required
                    rules={[
                        {
                            required: true,
                            message: '不能为空'
                        }
                    ]}
                >
                    <Input placeholder="请输入问题描述" />
                </Item>
                <Item
                    label="图片"
                    name="contentImage"
                    required
                    rules={[
                        {
                            required: true,
                            message: '不能为空'
                        }
                    ]}
                >
                    <Upload
                        action="/admin/common/uploadFile"
                        block
                        data={{
                            category: 'feedback'
                        }}
                    />
                </Item>
                <Item
                    label="发生问题url"
                    name="contentUrl"
                    required
                    rules={[
                        {
                            required: true,
                            message: '不能为空'
                        }
                    ]}
                >
                    <Input placeholder="请输入发生问题url" />
                </Item>
                <Item
                    label="手机号"
                    name="mobile"
                    required
                    rules={[
                        {
                            required: true,
                            message: '不能为空'
                        }
                    ]}
                >
                    <Input maxLength={11} placeholder="请输入手机号" />
                </Item>
                <Item
                    label="姓名"
                    name="name"
                    required
                    rules={[
                        {
                            required: true,
                            message: '不能为空'
                        }
                    ]}
                >
                    <Input placeholder="请输入姓名" />
                </Item>
            </Form>
        </Modal>
    );
};

export default HocUtils<AddFeedbackStore, ModalContainer>(
    AddFeedbackContext,
    AddFeedbackStore
)(observer(AddFeedbackModal));
