/* eslint-disable prettier/prettier */
export function iconArrowSvg(d: {
    width?: string | number;
    height?: string | number;
    color?: string;
} = {}) {
    return `<svg width="${d.width || 16}" height="${d.height || 16}" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.863 4.86a.667.667 0 0 1 1.011.863l-.07.083-5.334 5.31a.667.667 0 0 1-.858.07l-.082-.07-5.334-5.31a.667.667 0 0 1 .858-1.015l.083.07L8 9.702l4.863-4.841z" fill="${d.color || "currentColor"}"/>
</svg>
`;
}
