/*
 * @Author: mkRui
 * @Date: 2021-09-28 16:05:04
 * @LastEditTime: 2021-12-22 23:22:20
 */

import { Menu, MenuProps } from 'antd';
import { createHashHistory } from 'history';
import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IconBase from 'ye-home/src/components/icon-base/antd-icon';
import { API } from 'ye-home/src/types/api-types';
import { Search, Tree } from 'ye-home/src/utils/tree';
import withRouterEnhance, {
    MetaProps
} from 'ye-home/src/utils/with-router-enhance';

import MenuList from '../config';
import { MenuType } from '../types';

const { SubMenu } = Menu;

// todo 传入值需要优化
interface MenuList extends RouteComponentProps, MenuProps {
    config: API.MenuInfo.Data[];
    baseConfig: API.MenuInfo.Data[];
    handleClick?: (url: string) => void;
    selectMode?: 'front' | 'post-position';
    allOpenCheck?: boolean;
    theme?: 'light' | 'dark';
    isRouteJump?: boolean;
    openChange?: Function;
}

const MenuContainer: FC<MenuList & MetaProps> = (props) => {
    const {
        config,
        history,
        inlineCollapsed,
        mode,
        handleClick,
        baseConfig,
        selectMode,
        base,
        theme = 'light',
        allOpenCheck = false,
        isRouteJump = true,
        openChange
    } = props;

    const { location } = history;

    const [select, setSelect] = useState('');

    const [openKey, setOpenKey] = useState<string[]>([]);

    const getArr = (url: string) => {
        const search: any = Search(baseConfig, url);
        const selected = search[selectMode === 'front' ? 0 : search.length - 1];
        setOpenKey([...openKey, ...search.slice(0, search.length - 1)]);
        setSelect(`${selected}`);

        handleClick?.(url);
    };

    const menuItemClick = (item: MenuType) => {
        getArr(item.menuUrl);
        isRouteJump && createHashHistory().push(item.menuUrl);
        handleClick?.(item.menuUrl);
    };

    const handleOpenChange = (data: string[]) => {
        setOpenKey(data);
        openChange?.();
    };

    const MenuCom = (MenuList: MenuType[], isChild = false) => {
        // eslint-disable-next-line complexity
        return MenuList.map((item: MenuType) => {
            if (item.children?.length) {
                return (
                    <SubMenu
                        icon={
                            <div
                                style={{
                                    display:
                                        mode === 'horizontal' || isChild
                                            ? 'inline-flex'
                                            : 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <IconBase
                                    style={{
                                        position: 'relative',
                                        top:
                                            inlineCollapsed && !isChild
                                                ? '-5px'
                                                : '0px'
                                    }}
                                    name={item.menuIcon || 'FileOutlined'}
                                />
                                {inlineCollapsed && !isChild && (
                                    <div
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            fontSize: '12px',
                                            lineHeight: 1,
                                            position: 'relative',
                                            top: '-17px'
                                        }}
                                    >
                                        {item.menuName}
                                    </div>
                                )}
                            </div>
                        }
                        title={item.menuName}
                        key={item.id}
                    >
                        {!!item.children?.length &&
                            MenuCom(item.children, true)}
                    </SubMenu>
                );
            } else {
                return (
                    <Menu.Item
                        icon={
                            <div
                                style={{
                                    display:
                                        mode === 'horizontal' || isChild
                                            ? 'inline-flex'
                                            : 'flex',
                                    flexDirection:
                                        mode === 'horizontal' || isChild
                                            ? 'initial'
                                            : 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <IconBase
                                    style={{
                                        position: 'relative',
                                        top:
                                            mode === 'inline' &&
                                            !isChild &&
                                            inlineCollapsed
                                                ? '-5px'
                                                : '0px'
                                    }}
                                    name={item.menuIcon || 'FileOutlined'}
                                />
                                {mode === 'inline' &&
                                    !isChild &&
                                    inlineCollapsed && (
                                        <div
                                            style={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                fontSize: '12px',
                                                lineHeight: 1,
                                                position: 'relative',
                                                top: '-17px'
                                            }}
                                        >
                                            {item.menuName}
                                        </div>
                                    )}
                            </div>
                        }
                        key={item.id}
                        onClick={() => {
                            menuItemClick(item);
                        }}
                    >
                        {item.menuName}
                    </Menu.Item>
                );
            }
        });
    };

    useEffect(() => {
        if (config.length && !allOpenCheck) {
            getArr(base?.meta?.routerPosition || location.pathname);
        }
    }, [config, location.pathname]);

    useEffect(() => {
        handleClick?.(location.pathname);
    }, []);

    return (
        <Menu
            inlineCollapsed={inlineCollapsed}
            style={{
                border: 0,
                width: '100%',
                lineHeight: '50px'
            }}
            theme={theme}
            selectedKeys={[select]}
            openKeys={openKey.map((item) => `${item}`)}
            onOpenChange={handleOpenChange}
            mode={mode}
        >
            {MenuCom(config)}
        </Menu>
    );
};

MenuContainer.defaultProps = {
    selectMode: 'post-position'
};

export default withRouter(withRouterEnhance(MenuContainer));
