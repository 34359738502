/*
 * @Author: mkRui
 * @Date: 2021-10-04 15:28:24
 * @LastEditTime: 2021-11-28 15:16:36
 */
import { Spin, Upload } from 'antd';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload';
import ClassNames from 'classnames';
import { CSSProperties, FC, useState } from 'react';
import { baseURL } from 'ye-home/src/api';
import { toast } from 'ye-home/src/utils/messages';
import morStorage from 'ye-home/src/utils/local-storage';

import Styles from '../styles/upload.module.less';

interface CompressionUploadProps {
    action: string;
    value?: string;
    onChange?: (value: string) => void;
    onPath?: (value: string) => void;
    block?: boolean;
    inline?: boolean;
    style?: CSSProperties;
    size?: number;
}

const CompressionUpload: FC<
    CompressionUploadProps & Omit<UploadProps, 'onChange'>
> = (props) => {
    let {
        action,
        value,
        block,
        inline,
        style,
        children,
        size = 10,
        ...resetProps
    } = props;

    const actions = baseURL + action;

    const picHeaders = {
        token: `${window.localStorage.getItem('token')}`
    };

    const [loading, setLoading] = useState(false);

    const uploadContainer = ClassNames(`${Styles['upload__container']}`, {
        [`${Styles['upload--block']}`]: block,
        [`${Styles['upload--inline']}`]: inline
    });

    const isBlock = ClassNames({
        [`${Styles['upload--block']}`]: block
    });

    // 上传之前
    const beforeUpload = (file: RcFile) => {
        setLoading(true);
        let FILE;
        if (file.size / 1024 / 1024 > size) {
            toast('error', '超出文件大小');
            setLoading(false);
            return false;
        } else {
            return true;
        }
    };

    // 上传成功的回调
    const handleChange = (data: UploadChangeParam) => {
        if (data.file.status === 'done') {
            props.onChange?.(data.file.response.data.fileUrl);
            props.onPath?.(data.file.response.data.filePath);
            toast('success', '上传成功');
            setLoading(false);
        }
        if (data.file.status === 'error') {
            toast('error', '上传错误');
            setLoading(false);
        }
    };

    return (
        <div style={style} className={uploadContainer}>
            <Spin
                spinning={loading}
                wrapperClassName={Styles['spin__container']}
                style={{ height: '100%' }}
            >
                <Upload
                    {...resetProps}
                    action={actions}
                    showUploadList={false}
                    headers={picHeaders}
                    onChange={handleChange}
                    beforeUpload={beforeUpload}
                    className={isBlock}
                >
                    {children || (
                        <>
                            <div className={Styles['upload']}>
                                {value ? <img src={value} alt="" /> : '上传'}
                            </div>
                        </>
                    )}
                </Upload>
            </Spin>
        </div>
    );
};

export default CompressionUpload;
