/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-14 10:03:34
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.REPORT_MANAGEMENT_ERROR_REPORT_HYDROPOWER_ANOMALY,
        components: import('../views/hydropower-anomaly'),
        meta: {
            title: '水电错误'
        }
    },
    {
        path: Path.REPORT_MANAGEMENT_ERROR_REPORT_DOOR_LOCK_ERROR,
        components: import('../views/door-lock-error'),
        meta: {
            title: '门锁错误'
        }
    }
];

export default config;
