/*
 * @Author: mkRui
 * @Date: 2021-09-29 08:19:00
 * @LastEditTime: 2021-12-12 16:31:59
 */
import { Scrollbars } from 'react-custom-scrollbars';
import ClassNames from 'classnames';
import { observer } from 'mobx-react';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Menu from 'ye-home/src/components/menu';
import RootContext from 'ye-home/src/stores/root-context';
import { MenuModeEnum } from 'ye-home/src/types';

import Style from '../style/aside.module.less';

const LayoutAside: FC<RouteComponentProps> = (props) => {
    const container = useRef<any>();

    const timer = useRef<any>();

    const store = useContext(RootContext);

    const ClassN = ClassNames(`${Style['layout-aside']}`, {
        [`${Style['layout-aside_collapsed']}`]: store.collapsed
    });

    useEffect(() => {
        return () => {
            clearInterval(timer.current);
        };
    }, []);

    return (
        <div className={ClassN} ref={container}>
            <Scrollbars autoHide>
                <div className={Style['menu__container']}>
                    {store.menuMode === MenuModeEnum.MODE_COMBINATION && (
                        <h3>{store.menuName}</h3>
                    )}
                    <Menu
                        baseConfig={store.menuInfo}
                        config={store.menuAsideList}
                        inlineCollapsed={store.collapsed}
                        allOpenCheck={store.collapsed}
                        mode="inline"
                        theme="dark"
                    />
                </div>
            </Scrollbars>
        </div>
    );
};

export default withRouter(observer(LayoutAside));
