import { Modal } from 'antd';
import { observer } from 'mobx-react';
import { FC, useContext } from 'react';
import Context from 'ye-home/src/stores/root-context';
import { MenuModeEnum } from 'ye-home/src/types';

import Styles from '../styles/main.module.less';

export interface ThemeModalProps {
    title: string;
    onClose: () => void;
}

const ThemeModal: FC<ThemeModalProps> = (props) => {
    const { title, onClose } = props;

    const store = useContext(Context);

    const menuMode = [
        {
            title: '侧边菜单栏',
            img: 'https://gw.alipayobjects.com/zos/rmsportal/JopDzEhOqwOjeNTXkoje.svg',
            mode: MenuModeEnum.MODE_ASIDE
        },
        {
            title: '顶部菜单栏',
            img: 'https://gw.alipayobjects.com/zos/rmsportal/KDNDBbriJhLwuqMoxcAr.svg',
            mode: MenuModeEnum.MODE_HEADER
        },
        {
            title: '复合菜单栏',
            img: 'https://gw.alipayobjects.com/zos/antfincdn/x8Ob%26B8cy8/LCkqqYNmvBEbokSDscrm.svg',
            mode: MenuModeEnum.MODE_COMBINATION
        }
    ];

    const handleMode = (mode: MenuModeEnum) => {
        store.$setMenuMode(mode);
    };

    return (
        <Modal footer={null} title={title} onCancel={onClose} visible={true}>
            <div className={Styles['container']}>
                <div>主题模式</div>
                <div>
                    <h4>导航模式</h4>
                    <div className={Styles['menu-mode']}>
                        {menuMode.map((item, index) => (
                            <span key={index}>
                                <img
                                    onClick={() => handleMode(item.mode)}
                                    src={item.img}
                                    width="48"
                                    height="45"
                                    alt=""
                                />
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default observer(ThemeModal);
