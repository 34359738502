/*
 * @Author: mkRui
 * @Date: 2021-10-02 00:42:40
 * @LastEditTime: 2021-10-02 08:58:12
 */
import { CompressOutlined, ExpandOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';

import Style from '../style/screen.module.less';
const Screen = () => {
    const [screen, setScreen] = useState(false);

    // 退出全屏
    const exitFullscreen = () => {
        let de = document;
        if (de.exitFullscreen) {
            de.exitFullscreen();
        }
    };

    // 全屏
    const requestFullScreen = () => {
        let de = document.documentElement;
        if (de.requestFullscreen) {
            de.requestFullscreen();
        }
    };

    // 全屏
    const switchScreen = () => {
        if (!screen) {
            requestFullScreen();
        } else {
            exitFullscreen();
        }
        setScreen(!screen);
    };

    return (
        <div className={Style['screen']} onClick={switchScreen}>
            {screen ? <CompressOutlined /> : <ExpandOutlined />}
        </div>
    );
};

export default Screen;
