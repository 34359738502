/*
 * @Author: mkRui
 * @Date: 2021-11-02 22:03:08
 * @LastEditTime: 2021-11-02 22:51:36
 */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { AxiosInstance } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { Store } from 'mor-request';
import { createContext } from 'react';

import Api from '../api';
import { API } from '../types';
export class RemindStore extends Store<Api> {
    public data: API.UserMessageCount.Data = 0;

    constructor(axios: AxiosInstance) {
        super(new Api(axios));
        makeObservable(this, {
            data: observable,
            setData: action
        });
    }

    public setData(data: API.UserMessageCount.Data) {
        this.data = data;
    }

    public async getData() {
        const res = await this.api.getMessageCount();
        if (res && res.code === 0) this.setData(res.data);
    }
}

const RemindStoreContext = createContext<RemindStore>({} as RemindStore);

export default RemindStoreContext;
