// 签约类型
export enum AgreementTypeEnum {
    // 纸质合同
    PAPER = 1,
    // 电子合同
    ELECTRONICS = 2,
    // 无
    NULL = ''
}

// 性别
export enum GenderEnum {
    // 未知
    // UNKNOWN = 0,
    // 男
    MALE = 1,
    // 女
    FEMALE = 2
}

export enum DeviceTypeEnum {
    // 通用设备
    COMMON = 0,
    // 智能门锁
    LOCK = 1,
    // 智能热水表
    HOT_WATER_METER = 2,
    // 智能冷水表
    COLD_WATER_METER = 3,
    // 智能电表
    WATT_HOUR_METER = 4
}

export enum DeviceEleTypeEnum {
    // 智能电表(尖)
    WATT_HOUR_METER_TINE = 'TINE',
    // 智能电表(峰)
    WATT_HOUR_METER_PEAK = 'PEAK',
    // 智能电表(平)
    WATT_HOUR_METER_FLAT = 'FLAT',
    // 智能电表(谷)
    WATT_HOUR_METER_GRAIN = 'GRAIN'
}

export enum StateEnum {
    // 有效
    EFFECTIVE = 1,
    // 无效
    INVALID = 2
}

// 状态
export enum DisabledState {
    NORMAL = 1,
    DISABLE = 2
}

export enum RentBillTypeEnum {
    BY_NATURAL_MONTH = 1,
    BY_CROSS_MONTH = 2
}

export enum RentTypeEnum {
    // 整租
    WHOLE_RENT = 1,
    // 散租
    SCATTERED_RENT = 2,
    // 宿舍
    DORMITORY_RENT = 3
}

// 是否入住
export enum MeterRadEnum {
    // 无需结算
    NO_SETTLEMENT_REQUIRED = 0,
    // 单表无公摊结算
    NO_SHARED_STATEMENT = 1,
    // 按租约公摊结算
    SHARED_SETTLEMENT_LEASE = 2,
    // 按入住数公摊结算
    SHARED_SETTLEMENT_OCCUPANCY = 3
}

// 证件类型
export enum IdNoTypeEnum {
    // 大陆身份证
    ID_CARD = 1,
    // 台湾往来大陆通行证
    TAI_WAN = 2,
    // 澳门往来通行证
    MACAO = 3,
    // 香港往来通行证
    HONG_KONG = 4,
    // 护照
    PASSPORT = 5,
    // 其他
    OTHER = 9
}

export enum ReserveRentStateEnum {
    ALL = 0,
    // 预订未支付
    SCHEDULED_UNPAID = 1,
    // 已预订
    BOOKED = 2,
    // 已签约
    SIGNED = 3,
    // 已退订
    RETIRED = 4,
    // 已取消
    CANCEL = 5
}

export enum RenewalStateEnum {
    ALL = 0,
    // 未签约
    NOT_SIGNED = 1,
    // 已签约
    SIGNED = 2,
    // 已取消
    CANCEL = 3
}

export enum SignTypeEnum {
    // 直接签约
    NOW = 1,
    // 预订转签约
    MAKE = 2
}

export enum PayTypeEnum {
    ADA_PAY = 10,
    ALIPAY_APP_PAYMENT = 11,
    WECHAT_APP_PAYMENT = 12,
    WECHAT_APPLICATION_PAYMENT = 13,
    ALIPAY_APPLICATION_PAYMENT = 14,
    ALIPAY_QR_CODE_PAYMENT = 15,
    WECHAT_QR_CODE_PAYMENT = 16,
    PUBLIC_LOANS = 17,
    BANK_PAY = 18,
    WECHAT_PAYMENT = 19,
    ALIPAY_PAYMENT = 20,
    CASH_PAYMENT = 21,
    OWNER_OFFLINE_PAYMENT = 22,
    REFUND = 31
}

export enum CheckoutRuleEnum {
    // 1：不退押金不退租金，
    NO_DEPOSIT_NO_RENT = 1,
    // 2：退租金不退押金，
    DEPOSIT_NO_RENT = 2,
    // 3：退押金不退租金，
    RENT_NO_DEPOSIT = 3,
    // 4:退租金退押金
    RENT_DEPOSIT = 4
}

// 优惠券规则
export enum CouponRuleEnum {
    // 1：满减金额，
    FULL_REDUCTION_AMOUNT = 1,
    // 2：立减金
    REDUCED_AMOUNT = 2
}

export enum TrueOrFalseEnum {
    TRUE = 1,
    FALSE = 0
}

export enum CheckOrNoEnum {
    TRUE = 1,
    FALSE = 2
}

// 优惠券类型
export enum CouponCategoryEnum {
    // 1: 水电费
    WATER_ELECTRICITY = 1,
    // 2： 租金
    RENT = 2
}

export enum BranchApproveCheckType {
    CheckUser = 1,
    CheckRole = 2
}

// 导航模式
export enum MenuModeEnum {
    MODE_ASIDE = 'aside',
    MODE_HEADER = 'header',
    MODE_COMBINATION = 'combination'
}

export enum PayStateEnum {
    UNPAID = 0,
    PAID = 1,
    FAIL_PAID = 2,
    CANCEL_PAID = 9
}

export enum PurposeEnum {
    // 长租房
    LONG_TERM_RENTAL = 1,
    // 自用房
    SELF_USE = 2,
    // 员工宿舍
    STAFF_DORMITORY = 3
}

export enum RentStateEnum {
    // 未租
    NOT_RENTED = 1,
    // 已租
    RENTED = 2,
    // 已预订
    BOOKED = 3,
    // 暂停使用
    SUSPENDED = 4
}

export enum CategoryEnum {
    // 运营平台
    OPERATION_PLATFORM = 1,
    // 长租公寓
    LONG_RENT_APARTMENT = 2
}

export enum HrefTypeEnum {
    URL = 'url',
    PAGE = 'page'
}

export enum CheckoutCategoryEnum {
    GENERAL_CHECK_OUT = 1,
    CHECK_OUT_ON_CREDIT = 2
}

export enum CheckoutTypeEnum {
    EARLY_CHECK_OUT = 1,
    CHECK_OUT = 2,
    DELAY_CHECK_OUT = 3
}

export enum MemberTypeEnum {
    SIGNATORY = 1,
    ROOMMATE = 2
}

export enum TargetTypeEnum {
    RESERVE_BILL = 1,
    LEASE_BILL = 2,
    BRANCH_PAYMENT = 3,
    METER_BILL = 4,
    REFUND_BILL = 5,
    EXCHANGE_BILL = 6,
    CHECKOUT_BILL = 7
}

export enum CheckInStateEnum {
    NOT_SIGNED = 0,
    SIGNED = 1,
    CHECKED_IN = 2,
    CHECKED_OUT = 3,
    CANCELLED = 9
}

export enum AccountStateEnum {
    NOT_ACTIVE = 0,
    NORMAL = 1,
    DISABLED = 2
}

export enum CheckStateEnum {
    NO_SUBMIT = 0,
    CHECKING = 1,
    SUCCESS = 2,
    FAIL = 3
}

export enum CheckoutCheckStateEnum {
    NOT_SIGNED = 0,
    APPLY = 1,
    REFUND = 2,
    SUCCESS = 3,
    CANCEL = 4
}

export enum SmsStateEnum {
    NOT_SEND = 0,
    SEND = 1,
    FAIL = 2
}

export enum RentClassEnum {
    MONTH_RENT = 1
}

export enum RetreatTypeEnum {
    DEFAULT_CANCELLATION = 1
}

export enum RetreatCheckStateEnum {
    APPLYING = 0,
    SUCCESS = 1,
    FAIL = 2
}

export enum RefundTypeEnum {
    RESERVE = 1,
    CHECKOUT_OUT = 2,
    EXCHANGE = 3
}

export enum ContractStateEnum {
    NOT_CHECKOUT = 0,
    NOT_SIGNED_CHECKOUT = 1,
    SIGNED = 2,
    SIGNED_CANCEL = 3
}

export enum ExchangeContractStateEnum {
    NOT_SIGNED = 0,
    SIGNED_CHECK_IN = 1,
    CHECK_IN = 2,
    CHECK_OUT = 3,
    CANCEL = 9
}

export enum AccountOpeningEnum {
    INITIALIZATION = 'O',
    REVIEWED = 'A',
    AUDIT_FAILED = 'B',
    ACCOUNT_OPENING_FAILED = 'C',
    ACCOUNT_OPENING_SUCCESS_NO_CREATED = 'D',
    ACCOUNT_OPENING_SUCCESS = 'E'
}

export enum AdaPayTargetTypeEnum {
    PAY_ORDER = 1,
    OWNER_APPLY = 2,
    PERSON_APPLY = 3,
    BRANCH_APPLY = 4
}

export enum TransferStateEnum {
    NO_TRANSFER = 0,
    IN_TRANSFER = 1,
    SUCCESS_TRANSFER = 2,
    FAIL_TRANSFER = 3
}

export enum WithdrawalStateEnum {
    UNDER_REVIEW = 0,
    IN_ACCOUNT_TRANSFER = 1,
    IN_TRANSFER = 2,
    SUCCESS_WITHDRAWAL = 3,
    FAIL_WITHDRAWAL = 4,
    CANCEL_WITHDRAWAL = 9
}

export enum WithdrawalTargetTypeEnum {
    BRANCH_WITHDRAWAL = 1,
    OWNER_WITHDRAWAL = 2,
    PERSON_WITHDRAWAL = 3
}

// 挂帐类型
export enum SuspenseStatusEnum {
    APPLYING = 1,
    PAYMENTS = 2,
    FINISH = 3
}

export enum SuspenseCheckoutTypeEnum {
    BREAK_CONTRACT = 1,
    EARLY_CHECKOUT = 2
}

export enum RefundStateEnum {
    REFUND_BALANCE = 1,
    REFUND_BANK = 2
}

export enum RegisterTypeEnum {
    LEASE_REGISTER = 1,
    RESERVE_REGISTER = 2,
    WE_CHAT_REGISTER = 3,
    ALI_PAY_REGISTER = 4
}

export enum HandoverFlowStateEnum {
    UNSIGNED = 1,
    SIGNED = 2,
    CANCELLED = 3
}

export enum AppointmentStateEnum {
    UNTREATED = 1,
    PROCESSED = 2,
    TRIP_CANCELLATION = 3
}

export enum CheckOutCheckStateEnum {
    NOT_SIGNED = 0,
    SIGNED = 1,
    REFUND = 2,
    SUCCESS = 3,
    CANCELLED = 4
}

export enum CostEnum {
    // 一次性费用
    ONE_TIME_FEE = 1,
    // 周期性费用
    RECURRING_EXPENSES = 2,
    // 押金
    DEPOSIT = 3,
    // 分类
    CATEGORY = 0
}

export enum AppTypeEnum {
    // 小程序
    APPLET = 1,
    // APP
    APP = 2
}
export enum RenewalTypeEnum {
    MONTH_RENT = 1
}

export enum TimeTypeEnum {
    REMITTANCE = 1,
    TRANSFER = 2,
    CREATE = 3
}
