/*
 * @Author: mkRui
 * @Date: 2021-10-17 11:34:54
 * @LastEditTime: 2021-10-20 08:51:59
 */

import { observer } from 'mobx-react';
import { FC, useContext, useEffect } from 'react';

import BaseUrl from '../config/config';
import context from '../store';

interface ImgVCodeProps {
    time: number;
}

const ImgVCode: FC<ImgVCodeProps> = (props) => {
    const { time } = props;

    const loginStore = useContext(context);

    const origin = window.location.origin;

    const refresh = () => {
        loginStore.refreshVercodeId();
    };

    useEffect(() => {
        refresh();
    }, [time]);

    return (
        <img
            style={{
                width: '100%',
                height: '40px',
                marginTop: '1px'
            }}
            onClick={refresh}
            src={`${origin}${BaseUrl}/admin/auth/loginCaptcha?vercodeId=${loginStore.params.vercodeId}`}
            alt=""
        />
    );
};

export default observer(ImgVCode);
