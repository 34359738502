/*
 * @Author: mkRui
 * @Date: 2021-10-30 12:02:24
 * @LastEditTime: 2021-12-19 23:24:35
 */
import React, { FC } from 'react';
import axios from 'ye-home/src/api';

function HocUtils<T, S>(Context: React.Context<T>, Store: any) {
    return function (Component: FC<S>, store?: any) {
        const STORE = new Store(axios);

        return function (props: S) {
            return (
                <Context.Provider value={store ? store : STORE}>
                    <Component {...props} />
                </Context.Provider>
            );
        };
    };
}

export default HocUtils;
