/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-28 00:40:23
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.SYSTEM_SETTING_LOG_OPERATION,
        components: import('../views/operation'),
        meta: {
            title: '操作日志'
        }
    },
    {
        path: Path.SYSTEM_SETTING_LOG_ABNORMAL,
        components: import('../views/abnormal'),
        meta: {
            title: '错误日志'
        }
    },
    {
        path: Path.SYSTEM_SETTING_LOG_SLOG,
        components: import('../views/slog'),
        meta: {
            title: '短信日志'
        }
    }
];

export default config;
