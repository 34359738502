/*
 * @Author: mkRui
 * @Date: 2021-09-28 15:37:27
 * @LastEditTime: 2021-09-29 08:12:41
 */
import RootStore, { store } from 'ye-home/src/stores/root';
import Context from 'ye-home/src/stores/root-context';
import HocUtils from 'ye-home/src/utils/hoc-utils';
import { MetaProps } from 'ye-home/src/utils/with-router-enhance';

import Main from './views/main';

// todo 类型需要改进
export default HocUtils<RootStore, {}>(Context, RootStore)(Main as any, store);
