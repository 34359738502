/*
 * @Author: mkRui
 * @Date: 2021-10-30 12:02:24
 * @LastEditTime: 2021-12-19 23:24:35
 */
import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ConfigProps } from 'ye-home/src/router/config';

const modulesFiles = require.context('../', true, /\/config\.ts$/);
const router: any = [];
modulesFiles.keys().forEach((item) => {
    const url = modulesFiles(item).default;
    if (typeof url === 'object') {
        router.push(...url);
    }
});

export interface MetaProps extends RouteComponentProps {
    base?: ConfigProps;
}

function withRouterEnhance<T>(Component: FC<MetaProps & T>) {
    return function (props: MetaProps & T) {
        const { history } = props;

        const { location } = history;

        const routerBate = router.find(
            (item: any) => item.path === location.pathname
        );

        return <Component {...props} base={routerBate} />;
    };
}

export default withRouterEnhance;
