/*
 * @Author: mkRui
 * @Date: 2021-09-29 09:25:50
 * @LastEditTime: 2021-12-27 03:04:30
 */
import {
    DownOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined
} from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import ClassNames from 'classnames';
import { createHashHistory } from 'history';
import { observer } from 'mobx-react';
import { FC, useContext } from 'react';
import Avatar from 'ye-home/src/components/avatar';
import MenuBase from 'ye-home/src/components/menu';
import { submitFeedback } from 'ye-home/src/micro/add-feedback';
import Branch from 'ye-home/src/micro/branch';
import Remind from 'ye-home/src/micro/remind';
import Screen from 'ye-home/src/micro/screen';
import { themeModalShow } from 'ye-home/src/micro/theme-modal';
import Path from 'ye-home/src/router/path';
import RootContext from 'ye-home/src/stores/root-context';
import { MenuModeEnum } from 'ye-home/src/types';
import morStorage from 'ye-home/src/utils/local-storage';

import Style from '../style/header.module.less';

const LayoutHeader: FC = () => {
    const store = useContext(RootContext);

    const click = async ({ key }: { key: string }) => {
        switch (key) {
            case 'logout':
                await store.logout();
                createHashHistory().push(Path.LOGIN);
                break;
            case 'feedback':
                submitFeedback();
                break;
            case 'theme':
                themeModalShow();
                break;
            default:
                createHashHistory().push(key);
        }
    };

    // const headerLogo = () => {
    //     return ClassNames(`${Style['header__logo']}`);
    // };

    const headerLogo = () => {
        return ClassNames(`${Style['header__logo']}`, {
            [`${Style['header__logo--collapsed']}`]: store.collapsed
        });
    };

    const menuList = (
        <Menu onClick={click}>
            <Menu.Item key={Path.PERSONAL_CENTER}>个人中心</Menu.Item>
            <Menu.Item key={Path.PERSONAL_CENTER_PASSWORD}>修改密码</Menu.Item>
            <Menu.Item key="feedback">提交反馈</Menu.Item>
            {/* <Menu.Item key="theme">主题设置</Menu.Item> */}
            <Menu.Item key="logout">退出登录</Menu.Item>
        </Menu>
    );

    return (
        <div className={Style['header']}>
            <div className={Style['header--flex-start']}>
                <div className={headerLogo()}>
                    <div className={Style['header-block_icon']}>
                        <img
                            src={morStorage.getItem('logo')}
                            width="32"
                            height="32"
                            alt=""
                        />
                    </div>
                    <span className={Style['header--title']}>
                        {morStorage.getItem('userInfo', true).appName}
                    </span>
                </div>
            </div>
            <div className={Style['header-block_function']}>
                <div className={Style['header-block_trigger']}>
                    {store.menuMode !== MenuModeEnum.MODE_HEADER && (
                        <div
                            onClick={() =>
                                store.$setCollapsed(!store.collapsed)
                            }
                        >
                            {store.collapsed ? (
                                <MenuFoldOutlined />
                            ) : (
                                <MenuUnfoldOutlined />
                            )}
                        </div>
                    )}
                    {store.menuMode !== MenuModeEnum.MODE_ASIDE && (
                        <MenuBase
                            baseConfig={store.menuInfo}
                            handleClick={(url: string) => {
                                store.$setMenuUrl(url);
                            }}
                            allOpenCheck={true}
                            isRouteJump={
                                store.menuMode === MenuModeEnum.MODE_HEADER
                            }
                            mode="horizontal"
                            selectMode="front"
                            config={store.menuHeaderList}
                        />
                    )}
                </div>
                <div className={Style['header-block_user__container']}>
                    <Branch>
                        <div>
                            <span
                                style={{
                                    cursor: 'pointer'
                                }}
                                className={Style['header__text']}
                            >
                                <i
                                    style={{
                                        marginRight: '10px',
                                        fontStyle: 'normal',
                                        fontSize: '14px'
                                    }}
                                >
                                    {!!store.initInfo.branchId && (
                                        <span>{store.initInfo.branchName}</span>
                                    )}
                                </i>
                                <DownOutlined />
                            </span>
                        </div>
                    </Branch>
                    <Remind />
                    <Screen />
                    <Dropdown trigger={['click']} overlay={menuList}>
                        <div className={Style['header-block_user']}>
                            <Avatar url={store.userInfo.avatar} />
                            <span>{store.userInfo.name}</span>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};

export default observer(LayoutHeader);
