/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * @Author: Fullsize
 * @Date: 2021-09-26 10:17:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-12-19 15:31:11
 * @FilePath: \ye-home\src\index.tsx
 */
import 'antd/dist/antd.variable.min.css';

import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import ReactDOM from 'react-dom';

import App from './layout';
import reportWebVitals from './reportWebVitals';
import { store } from './stores/root';
import RootContext from './stores/root-context';

ConfigProvider.config({
    theme: {
        primaryColor: '#1890ff'
    }
});

// <React.StrictMode> 严格模式

ReactDOM.render(
    <RootContext.Provider value={store}>
        <ConfigProvider locale={zhCN}>
            <App />
        </ConfigProvider>
    </RootContext.Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
