/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-10-25 13:56:03
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.TENANT_MANAGEMENT_DETAIL,
        components: import('../views/tenant-detail'),
        meta: {
            title: '租户详情'
        }
    },
    {
        path: Path.TENANT_MANAGEMENT_FEEDBACK,
        components: import('../views/tenant-feedback'),
        meta: {
            title: '问题反馈'
        }
    },
    {
        path: Path.TENANT_MANAGEMENT_LIST,
        components: import('../views/tenant-list'),
        meta: {
            title: '品牌列表'
        }
    }
];

export default config;
