/*
 * @Author: mkRui
 * @Date: 2021-09-26 10:16:38
 * @LastEditTime: 2021-12-16 12:20:24
 */
import * as Page from './page-all';
import Path from './path';

interface MetaProps {
    title: string;
    routerPosition?: string;
}
export interface ConfigProps {
    path: Path;
    components: any;
    meta?: MetaProps;
}

const config: ConfigProps[] = [
    // demo
    {
        path: Path.DEMO,
        components: Page.Demo
    },
    // demo
    {
        path: Path.LOGIN_CHECK,
        components: Page.LoginCheck
    },
    // 首页
    {
        path: Path.DASHBOARD,
        components: Page.Dashboard
    },
    // 门店-首页
    {
        path: Path.BRANCH_DASHBOARD,
        components: Page.BranchDashboard
    },
    // 个人中心
    {
        path: Path.PERSONAL_CENTER,
        components: Page.PersonalCenter,
        meta: {
            title: '用户列表'
        }
    },
    // 用户管理
    {
        path: Path.TENANT_MANAGEMENT,
        components: Page.TenantManagement,
        meta: {
            title: '用户管理'
        }
    },
    // 租约列表
    {
        path: Path.OPERATIONS_MANAGEMENT,
        components: Page.OperationsManagement,
        meta: {
            title: '租约列表'
        }
    },
    // 服务管理
    {
        path: Path.SERVICE_MANAGEMENT,
        components: Page.ServiceManagement,
        meta: {
            title: '服务管理'
        }
    },
    // 财务管理
    {
        path: Path.FINANCIAL_MANAGEMENT,
        components: Page.FinancialManagement,
        meta: {
            title: '财务管理'
        }
    },
    // 应用管理
    {
        path: Path.APPLICATION_MANAGEMENT,
        components: Page.ApplicationManagement,
        meta: {
            title: '应用管理'
        }
    },
    // 系统设置
    {
        path: Path.SYSTEM_SETTING,
        components: Page.SystemSetting,
        meta: {
            title: '系统设置'
        }
    },
    // 用户管理
    {
        path: Path.USER_MANAGEMENT,
        components: Page.UserManagement,
        meta: {
            title: '用户管理'
        }
    },
    // 房态中心
    {
        path: Path.ATRIAL_STATE_CENTER,
        components: Page.AtrialStateCenter,
        meta: {
            title: '房态中心'
        }
    },
    // 报表管理
    {
        path: Path.REPORT_MANAGEMENT,
        components: Page.ReportManagement,
        meta: {
            title: '报表管理'
        }
    },
    // 设备报警
    {
        path: Path.DEVICE_WARN,
        components: Page.DeviceWarn,
        meta: {
            title: '设备报警'
        }
    }
];

export default config;
