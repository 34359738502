/* eslint-disable @typescript-eslint/consistent-type-assertions */
/*
 * @Author: Fullsize
 * @Date: 2021-09-26 16:25:53
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-10 14:58:31
 * @FilePath: /ye-home/src/stores/root_contxt.ts
 */
import { createContext } from 'react';

import RootStore from './root';
const RootContext = createContext<RootStore>({} as RootStore);

export default RootContext;
