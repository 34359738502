/*
 * @Author: mkRui
 * @Date: 2021-10-20 19:59:57
 * @LastEditTime: 2021-11-26 01:23:54
 */
import { createHashHistory } from 'history';
import { CreateAxios } from 'mor-request';
import Path from 'ye-home/src/router/path';
import { messages } from 'ye-home/src/utils/messages';
const IS_DEV = process.env.NODE_ENV !== 'production';

export const baseURL = IS_DEV ? '/api' : '';

const axios = CreateAxios(
    {
        baseURL,
        timeout: 15000
    },
    ({ type, msg, code }) => {
        if (code !== 2 && type === 'error') {
            messages('error', msg);
        } else if (code === 2) {
            createHashHistory().replace(Path.LOGIN);
        }
    }
);

export default axios;
