/*
 * @Author: mkRui
 * @Date: 2021-10-04 15:28:24
 * @LastEditTime: 2021-11-28 15:16:36
 */
import { CloseOutlined } from '@ant-design/icons';
import { UploadProps } from 'antd/lib/upload';
import ClassNames from 'classnames';
import { FC, useEffect, useState } from 'react';

import Styles from '../styles/upload-list.module.less';
import Upload from './upload';

interface UploadListProps {
    action: string;
    value?: string | string[];
    onChange?: (value: string | string[]) => void;
    dataType: 'array' | 'string';
}

const UploadList: FC<UploadListProps & Omit<UploadProps, 'onChange'>> = (
    props
) => {
    let { action, value, style, onChange, dataType, ...resetProps } = props;

    const [paths, setPaths] = useState<string[]>([]);

    const uploadListContainer = ClassNames(
        `${Styles['upload-list__container']}`
    );

    const handleChange = (url: string) => {
        const p: string[] = ([] as string[]).concat(paths);
        p.push(url);
        setPaths(p);
        onChange?.(dataType === 'string' ? p.join(',') : p);
    };

    const handleDel = (url: string) => {
        const index = paths.findIndex((item) => item === url);
        const p: string[] = ([] as string[]).concat(paths);
        p.splice(index, 1);
        setPaths(p);
        onChange?.(dataType === 'string' ? p.join(',') : p);
    };

    useEffect(() => {
        if (value) {
            if (typeof value === 'object' && value.length) {
                setPaths(value as string[]);
            }
            if (typeof value === 'string') {
                setPaths((value as string).split(','));
            }
        }
    }, [value]);

    return (
        <div style={style} className={uploadListContainer}>
            <ul className={Styles['images__list']}>
                {paths.map((item) => (
                    <li key={item} className={Styles['images__item']}>
                        <div className={Styles['images__container__img']}>
                            <img
                                className={Styles['images__img']}
                                width="60"
                                height="60"
                                src={item}
                                alt=""
                            />
                            <div
                                className={
                                    Styles['images__container--position']
                                }
                            >
                                <div onClick={() => handleDel(item)}>
                                    <CloseOutlined
                                        style={{ fontSize: '12px' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            <div className={Styles['upload']}>
                <Upload
                    block
                    {...resetProps}
                    action={action}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
};

export default UploadList;
