/*
 * @Author: mkRui
 * @Date: 2021-09-28 10:32:12
 * @LastEditTime: 2021-09-28 15:25:59
 */
import React from 'react';

import Aside from './view/aside';
import LayoutBase, {
    BasicProps as LayoutProps,
    Header,
    Main,
    ContentBase,
    ContentFooter,
    ContentHeader,
    ContentMain,
    ContentLayout
} from './view/main';

interface LayoutType extends React.FC<LayoutProps> {
    Header: typeof Header;
    Main: typeof Main;
    Aside: typeof Aside;
}

interface ContentType extends React.FC<LayoutProps> {
    Header: typeof ContentHeader;
    Main: typeof ContentMain;
    Footer: typeof ContentFooter;
    Layout: typeof ContentLayout;
}

const Layout = LayoutBase as LayoutType;

const Content = ContentBase as ContentType;

Content.Footer = ContentFooter;

Content.Header = ContentHeader;

Content.Main = ContentMain;

Content.Layout = ContentLayout;

Layout.Header = Header;

Layout.Main = Main;

Layout.Aside = Aside;

export { Aside, Header, Main, Content };

export default Layout;
