/*
 * @Author: mkRui
 * @Date: 2021-10-17 14:11:35
 * @LastEditTime: 2021-12-19 14:41:32
 */
import { action, makeObservable, observable } from 'mobx';
import { Store } from 'mor-request';
import { createContext } from 'react';
import axios from 'ye-home/src/api';

import { Api } from '../api';
import { API } from '../types/api';

/* eslint-disable @typescript-eslint/consistent-type-assertions */
export class AuthStore extends Store<Api> {
    public authList: API.AuthList.Data[] = [];

    public params: API.Login.Params = {
        tenId: 0,
        username: '',
        password: '',
        vercodeId: Number(new Date()),
        vercode: ''
    };

    constructor() {
        super(new Api(axios));
        makeObservable(this, {
            authList: observable,
            params: observable,
            $setParams: action,
            $setAuthList: action
        });
    }

    public $setParams(params: Partial<API.Login.Params>) {
        Object.assign(this.params, {
            ...params
        });
    }

    public $setAuthList(data: API.AuthList.Data[]) {
        this.authList = data;
    }

    public login(params: API.Login.Params) {
        this.params = Object.assign(this.params, {
            ...params
        });
        return this.api.login(this.params);
    }

    public async getAuthList() {
        const res = await this.api.authList();
        if (JSON.stringify(res.data) !== '{}' && res.data.length) {
            this.$setAuthList(res.data);
            return res.data;
        }
    }

    public selectAuth(id: number) {
        this.params.tenId = id;
    }

    public refreshVercodeId() {
        this.params.vercodeId = Number(new Date());
    }
}

const AuthContext = createContext<AuthStore>({} as AuthStore);

export default AuthContext;
