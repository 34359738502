/*
 * @Author: mkRui
 * @Date: 2021-10-01 21:43:38
 * @LastEditTime: 2021-10-01 21:49:05
 */
import { FC } from 'react';

import Style from '../style/main.module.less';
const LayoutMain: FC = (props) => {
    return <div className={Style['main']}>{props.children}</div>;
};

export default LayoutMain;
