/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-12-11 22:39:28
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.OPERATIONS_MANAGEMENT_RETREAT_MANAGEMENT_LIST,
        components: import('../views/retreat-list'),
        meta: {
            title: '退订列表'
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_RETREAT_MANAGEMENT_DETAIL,
        components: import('../views/retreat-detail'),
        meta: {
            title: '退订详情',
            routerPosition: Path.OPERATIONS_MANAGEMENT_RETREAT_MANAGEMENT_LIST
        }
    },
    {
        path: Path.OPERATIONS_MANAGEMENT_RETREAT_MANAGEMENT_CHECK,
        components: import('../views/retreat-check-list'),
        meta: {
            title: '退订审批'
        }
    }
];

export default config;
