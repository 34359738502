/*
 * @Author: mkRui
 * @Date: 2021-10-16 10:06:51
 * @LastEditTime: 2021-10-21 21:28:42
 */
import React from 'react';
import ReactDOM from 'react-dom';

interface Options<State> {
    state: State;
    render: (state: State) => React.ReactElement;
    mountElement?: HTMLElement;
    callback?: () => void;
}

export default class RenderComponent<State> {
    state: State;
    private readonly options: Options<State>;
    private container!: HTMLElement;

    constructor(options: Options<State>) {
        this.options = options;
        this.state = options.state;
        this.init();
    }

    setState(state: Partial<State>) {
        Object.assign(this.state, state);

        this.render();
    }

    unmount() {
        const { container } = this;
        const parent = container.parentNode;

        ReactDOM.unmountComponentAtNode(container);

        if (parent) {
            parent.removeChild(container);
        }
    }

    private init() {
        const mountElement = this.options.mountElement || document.body;
        const container = document.createElement('div');

        container.className = 'DynamicRenderingContainer';

        mountElement.appendChild(container);

        this.container = container;

        this.render();
    }

    private render() {
        const { render, callback } = this.options;

        ReactDOM.render(render(this.state), this.container, callback);
    }
}
