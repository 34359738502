/*
 * @Author: mkRui
 * @Date: 2021-11-02 22:03:08
 * @LastEditTime: 2021-11-03 07:40:06
 */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { AxiosInstance } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { Store } from 'mor-request';
import { createContext } from 'react';

import Api from '../api';
import { API } from '../types';
export class BranchStore extends Store<Api> {
    public data: API.BranchList.Data[] = [];

    constructor(axios: AxiosInstance) {
        super(new Api(axios));
        makeObservable(this, {
            data: observable,
            setData: action
        });
    }

    public setData(data: API.BranchList.Data[]) {
        this.data = data;
    }

    public async getData() {
        const res = await this.api.getBranchList();
        if (res.code === 0) this.setData(res.data);
    }

    public changeBranch(params: API.ChangeBranch.Params) {
        return this.api.changeBranch(params);
    }
}

const BranchStoreContext = createContext<BranchStore>({} as BranchStore);

export default BranchStoreContext;
