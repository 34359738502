/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-06 16:59:24
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.SYSTEM_SETTING_ORGANIZATION_SETTING_ACCOUNT_MANAGEMENT,
        components: import('../views/account'),
        meta: {
            title: '账号管理'
        }
    },
    {
        path: Path.SYSTEM_SETTING_ORGANIZATION_SETTING_ROLE_DEPARTMENT,
        components: import('../views/department'),
        meta: {
            title: '部门管理'
        }
    },
    {
        path: Path.SYSTEM_SETTING_ORGANIZATION_SETTING_ROLE_MANAGEMENT,
        components: import('../views/role'),
        meta: {
            title: '角色管理'
        }
    }
];

export default config;
