/* eslint-disable import/no-unresolved */
/*
 * @Author: mkRui
 * @Date: 2021-09-26 10:16:38
 * @LastEditTime: 2021-12-16 12:19:48
 */
// 路径集合
import React from 'react';

// demo
export const Demo = import('ye-home/src/modules/demo');

// 登录校验
export const LoginCheck = import('ye-home/src/modules/login-check');

// 登录相关
export const Login = import('ye-home/src/micro/auth');

// 平台 - 首页
export const Dashboard = import('ye-home/src/modules/dashboard');

// 门店 - 首页
export const BranchDashboard = import('ye-home/src/modules/branch-dashboard');

// 登录相关
export const PersonalCenter = import('ye-home/src/modules/personal-center');

// 系统设置
export const SystemSetting = import('ye-home/src/modules/system-setting');

// 应用管理
export const ApplicationManagement = import(
    'ye-home/src/modules/application-management'
);

// 租户管理
export const TenantManagement = import('ye-home/src/modules/tenant-management');

// 服务管理
export const ServiceManagement = import(
    'ye-home/src/modules/service-management'
);

// 财务管理
export const FinancialManagement = import(
    'ye-home/src/modules/financial-management'
);

// 运营管理
export const OperationsManagement = import(
    'ye-home/src/modules/operations-management'
);

// 用户管理
export const UserManagement = import('ye-home/src/modules/user-management');

// 报表管理
export const ReportManagement = import('ye-home/src/modules/report-management');

// 房态中心
export const AtrialStateCenter = import(
    'ye-home/src/modules/atrial-state-center'
);

// 设备报警
export const DeviceWarn = import('ye-home/src/modules/device-warn');
