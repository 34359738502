/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-14 09:46:40
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.REPORT_MANAGEMENT_CUSTOMER_REPORT_TENANT_LIST,
        components: import('../views/tenant-list'),
        meta: {
            title: '租客列表'
        }
    },
    {
        path: Path.REPORT_MANAGEMENT_CUSTOMER_REPORT_CUSTOMER_WALLET,
        components: import('../views/customer-wallet'),
        meta: {
            title: '客户钱包'
        }
    }
];

export default config;
