/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-28 00:40:23
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.SYSTEM_SETTING_ORGANIZATION_SETTING,
        components: import('../organization-setting'),
        meta: {
            title: '组织管理'
        }
    },
    {
        path: Path.SYSTEM_SETTING_LOG,
        components: import('../log-management'),
        meta: {
            title: '日志管理'
        }
    },
    {
        path: Path.SYSTEM_SETTING_BASE_SETTING,
        components: import('../base-setting'),
        meta: {
            title: '基础设置'
        }
    },
    {
        path: Path.SYSTEM_SETTING_STORE_SETTING,
        components: import('../store-settings'),
        meta: {
            title: '门店设置'
        }
    },
    {
        path: Path.SYSTEM_SETTING_INTERFACE_SETTINGS,
        components: import('../interface-settings'),
        meta: {
            title: '接口设置'
        }
    },
    // 公司管理
    {
        path: Path.SYSTEM_SETTING_COMPANY_MANAGEMENT,
        components: import('../company-management'),
        meta: {
            title: '公司管理'
        }
    },
    // 财务设置
    {
        path: Path.SYSTEM_SETTING_FINANCIAL_SETTING,
        components: import('../financial-setting'),
        meta: {
            title: '财务设置'
        }
    },
    // 广告管理
    {
        path: Path.SYSTEM_SETTING_MOBILE__MANAGEMENT,
        components: import('../mobile-management'),
        meta: {
            title: '广告列表'
        }
    }
];

export default config;
