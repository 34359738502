/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-10-04 22:26:39
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.PERSONAL_CENTER_MESSAGE,
        components: import('../components/message'),
        meta: {
            title: '消息中心'
        }
    },
    {
        path: Path.PERSONAL_CENTER_PASSWORD,
        components: import('../components/password'),
        meta: {
            title: '设置密码'
        }
    }
];

export default config;
