/*
 * @Author: mkRui
 * @Date: 2021-12-08 00:07:42
 * @LastEditTime: 2021-12-19 14:31:12
 */
import RunComponents from 'ye-home/src/components/run-component';
import RootStore, { store } from 'ye-home/src/stores/root';
import Context from 'ye-home/src/stores/root-context';
import HocUtils from 'ye-home/src/utils/hoc-utils';

import Main, { ThemeModalProps } from './views/main';

const ThemeModal = HocUtils<RootStore, ThemeModalProps>(Context, RootStore)(
    Main,
    store
);

export const themeModalShow = () => {
    const res = new RunComponents({
        state: {},
        render: () => (
            <ThemeModal title="主题设置" onClose={() => res.unmount()} />
        )
    });
};

export default ThemeModal;
