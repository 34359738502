/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-14 09:42:58
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.REPORT_MANAGEMENT_CUSTOMER_REPORT,
        components: import('../modules/customer-report'),
        meta: {
            title: '用户报表'
        }
    },
    {
        path: Path.REPORT_MANAGEMENT_ERROR_REPORT,
        components: import('../modules/error-report'),
        meta: {
            title: '错误报表'
        }
    },
    {
        path: Path.REPORT_MANAGEMENT_FINANCIAL_REPORT,
        components: import('../modules/financial-report'),
        meta: {
            title: '财务报表'
        }
    },
    {
        path: Path.REPORT_MANAGEMENT_OPERATION_REPORT,
        components: import('../modules/operation-report'),
        meta: {
            title: '运营报表'
        }
    }
];

export default config;
