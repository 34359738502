/*
 * @Author: mkRui
 * @Date: 2021-12-27 00:24:56
 * @LastEditTime: 2021-12-27 03:02:44
 */
import RunComponents from 'ye-home/src/components/run-component';

import AddFeedbackModal from './modal';

export const AddFeedbackFn = () => {
    const res = new RunComponents({
        state: {},
        render: () => (
            <AddFeedbackModal
                onClose={() => {
                    res.unmount();
                }}
            />
        )
    });
};

export default AddFeedbackFn;
