/*
 * @Author: mkRui
 * @Date: 2021-10-02 00:42:40
 * @LastEditTime: 2021-11-28 15:38:28
 */
import { HomeOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react';
import { FC, useContext, useEffect } from 'react';
import RootContext from 'ye-home/src/stores/root-context';
import eventDispatch from 'ye-home/src/utils/event-dispatch';

import BranchStoreContext from '../store';
import Style from '../style/screen.module.less';

const Screen: FC = (props) => {
    const { children } = props;

    const store = useContext(BranchStoreContext);

    const root = useContext(RootContext);

    const handleClick = async ({ key }: { key: string }) => {
        await store.changeBranch({ id: Number(key) });
        // createHashHistory().replace(Path.ATRIAL_STATE_CENTER_CURRENT);
        // window.location.reload();
        eventDispatch.emit('refresh');
        eventDispatch.emit('changeBranch');
    };

    const menu = (
        <Menu
            onClick={handleClick}
            selectedKeys={[String(root.initInfo.branchId)]}
        >
            {store.data.map((item) => (
                <Menu.Item key={item.id}>{item.name}</Menu.Item>
            ))}
        </Menu>
    );

    useEffect(() => {
        store.getData();
    }, []);

    if (!store.data.length) {
        return <div />;
    }

    return (
        <div>
            <Dropdown
                overlay={menu}
                placement="bottomLeft"
                trigger={['click']}
                arrow={false}
            >
                {children || (
                    <div className={Style['screen']}>
                        <HomeOutlined />
                    </div>
                )}
            </Dropdown>
        </div>
    );
};

export default observer(Screen);
