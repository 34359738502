/*
 * @Author: mkRui
 * @Date: 2021-10-04 16:10:47
 * @LastEditTime: 2021-11-19 23:47:15
 */
import { ConfigProps } from 'ye-home/src/router/config';
import Path from 'ye-home/src/router/path';
const config: ConfigProps[] = [
    {
        path: Path.SYSTEM_SETTING_STORE_SETTING_BUILDING_MANAGEMENT,
        components: import('../view/building-management'),
        meta: {
            title: '楼栋管理'
        }
    },
    {
        path: Path.SYSTEM_SETTING_STORE_SETTING_EDITOR_STORE_TEMPLATE,
        components: import('../view/editor-store-template'),
        meta: {
            title: '编辑房源模版',
            routerPosition:
                Path.SYSTEM_SETTING_STORE_SETTING_STORE_TEMPLATE_LIST
        }
    },
    {
        path: Path.SYSTEM_SETTING_STORE_SETTING_EDITOR_STORE_SINGLE_TEMPLATE,
        components: import('../view/editor-store-single-template'),
        meta: {
            title: '编辑单间模版'
        }
    },
    {
        path: Path.SYSTEM_SETTING_STORE_SETTING_STORE_TEMPLATE_LIST,
        components: import('../view/store-template-list'),
        meta: {
            title: '房源模版'
        }
    },
    {
        path: Path.SYSTEM_SETTING_STORE_SETTING_STORE_SINGLE_TEMPLATE_LIST,
        components: import('../view/store-single-template-list'),
        meta: {
            title: '单间模版列表'
        }
    },
    {
        path: Path.SYSTEM_SETTING_STORE_SETTING_HANDOVER_ORDER,
        components: import('../view/handover-order'),
        meta: {
            title: '交接单'
        }
    },
    {
        path: Path.SYSTEM_SETTING_STORE_SETTING_HOUSING_RESOURCES_ENTRY,
        components: import('../view/housing-resources-entry'),
        meta: {
            title: '房源录入'
        }
    },
    {
        path: Path.SYSTEM_SETTING_STORE_SETTING_HOUSING_RESOURCES_ENTRY_SUBMIT,
        components: import('../view/housing-resources-entry-submit'),
        meta: {
            title: '房源提交',
            routerPosition:
                Path.SYSTEM_SETTING_STORE_SETTING_HOUSING_RESOURCES_ENTRY
        }
    },
    {
        path: Path.SYSTEM_SETTING_STORE_SETTING_ROOM_LIST,
        components: import('../view/room-list'),
        meta: {
            title: '房源管理'
        }
    },
    {
        path: Path.SYSTEM_SETTING_STORE_SETTING_ROOM_OWNER,
        components: import('../view/room-owner'),
        meta: {
            title: '房东管理'
        }
    },
    {
        path: Path.SYSTEM_SETTING_STORE_SETTING_ROOM_OWNER_OPTION,
        components: import('../view/room-owner-option'),
        meta: {
            title: '房东编辑',
            routerPosition: Path.SYSTEM_SETTING_STORE_SETTING_ROOM_OWNER
        }
    },
    {
        path: Path.SYSTEM_SETTING_STORE_SETTING_ROOM_DEVICE,
        components: import('../view/room-device'),
        meta: {
            title: '房间智能设备',
            routerPosition: Path.SYSTEM_SETTING_STORE_SETTING_ROOM_LIST
        }
    },
    {
        path: Path.SYSTEM_SETTING_STORE_SETTING_ROOM_SINGLE,
        components: import('../view/room-single'),
        meta: {
            title: '房间单间'
        }
    }
];

export default config;
