/*
 * @Author: mkRui
 * @Date: 2021-10-10 15:22:19
 * @LastEditTime: 2021-11-10 22:59:55
 */
import { Request } from 'mor-request';
import { API } from 'ye-home/src/types/api-types';
export class Api extends Request {
    // 初始化
    public init() {
        return this.get<API.InitInfo.Data>(`/admin/auth/init`);
    }

    // 当前用户
    public userInfo() {
        return this.get<API.UserInfo.Data>('/admin/common/getUserInfo');
    }

    // 获取菜单列表
    public getMenu() {
        return this.get<API.MenuInfo.Data[]>('/admin/common/getMenu');
    }

    // 获取门店配置

    // 退出登录
    public logOut() {
        return this.post('/admin/common/logout', {});
    }
}
